import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

export class NewBindingModal extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    this.props.onOK(this.props.mapping, this.props.parameters.map(param => {return { name:param, value: this[param].value} }));
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onClose} animation={false} backdrop="static">
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header>
            <Modal.Title>New Parameter Binding</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.props.parameters.map((param,index) =>
            <Form.Group key={index}>
              <Form.Label>{param}</Form.Label>
              <Form.Control ref={node => (this[param] = node)}/>
            </Form.Group>)}
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="primary">
              OK
            </Button>
            <Button variant="secondary" onClick={this.props.onClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
  )}
}

export default NewBindingModal;
