import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
// import { splice } from '../../utils/functions.js';
import { executeMapping, updateMapping, downloadMapping, createMapping, deleteMapping, uploadMappingAuxiliaryFile, createFile, viewMappingExecution, downloadMappingExecution, clearMappingExecution, createMappingParameterBinding, deleteMappingParameterBinding } from '../../utils/APIUtils';
import { getMappings, getFiles, deleteFile, viewFile, updateFile } from '../../utils/APIUtils';
import { convert } from '../../utils/D2RMLUtils';
import {  addDataset, removeDataset} from '../../utils/APIUtils';

import D2RMLEditor from "./d2rml/D2RMLEditor.js";
import NewMappingUploadModal from "./modals/NewMappingUploadModal.js";
import NewDataUploadModal from "./modals/NewDataUploadModal.js";
import NewBindingModal from "./modals/NewBindingModal.js";
import PublishModal from "./modals/PublishModal.js";
import ExecutableState from "./ExecutableState.js";
import PublishableState from "./PublishableState.js";
import IndexableState from "./IndexableState.js";
import ResultsModal from "./modals/ResultsModal.js";
import DeleteModal from "./modals/DeleteModal.js";
import NewDatasetLinkModal from "./modals/NewDatasetLinkModal.js";

export class DatasetEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {

      mappings: [],
      currentMappingIndex: null,

      files: [],
      currentFileIndex: null,

      newMappingModalShow: false,
      newMappingModalMapping: null,

      newBindingModalShow: false,
      parameters: [],
      mappingId: null,

      newDataModalShow: false,

      newDatasetLinkModalShow: false,
      publishModalShow: false,

      resultsShow: false,
      resultsState: { loaded: false, loading:false, failed:false },

    };

     this.datasetUpdated(props);
  }

  componentWillReceiveProps(props) {
    // console.log(props.dataset)
    if (this.props.dataset.id !== props.dataset.id) {

      this.currentMappingIndex = null;
      this.datasetUpdated(props)
    }
      // this.setState(this.createMapping(props));

  }

  addDataset(id, toId) {
    addDataset(id, toId)
      .then(() => {
        this.props.actions('add-dataset-to-catalog', { id: id})
      })
  }

  removeDataset(id, fromId) {
    removeDataset(id, fromId)
      .then(() => {
        this.props.actions('remove-dataset-from-catalog', { id: id})
      })
  }

  mappingsSort(a,b) {
       if (a.type === "PREFIX" && (b.type === "HEADER" || b.type === "CONTENT")) {
         return -1;
       } else if (a.type === "HEADER" && b.type === "CONTENT") {
         return -1;
       } else if (a.type === "HEADER" && b.type === "PREFIX") {
         return 1;
       } else if (a.type === "CONTENT" && (b.type === "PREFIX" || b.type === "HEADER")) {
         return 1;
       } else {
         if (a.name < b.name) {
           return -1;
         } else if (a.name > b.name) {
           return 1;
         } else {
           return 0;
         }
       }
  }

  fielsSort(a,b) {
       if (a.name < b.name) {
         return -1;
       } else if (a.name > b.name) {
         return 1;
       } else {
         return 0;
       }
  }

  datasetUpdated(props) {
    if (props.dataset.id) {
      const _this = this;
      getMappings(props.dataset.id)
        .then(response => {
          response.sort(this.mappingsSort);
          _this.setState({ mappings: response, currentMappingIndex: null });
        })

      getFiles(props.dataset.id)
        .then(response => {
          _this.setState({ files: response, currentFileIndex: null });
        })

    }
  }

  handleServerEvent(message) {
    // console.log(message);
    if (message.state === "EXECUTING") {
      if (message.startedAt) {
        // console.log(message.startedAt);
        if (message.instanceId) {
          this.setState({ mappings: this.state.mappings.map(el =>
              el.id !== message.id ?
                 el : {...el, instances: el.instances.map(inst => inst.id === message.instanceId ?
                   { ...inst, [message.type +'State']: message.state, [message.type + 'StartedAt']: message.startedAt, maps: message.maps.map(m => {return{...m, completed:false}})} : inst)}
          )});
        } else {
          this.setState({ mappings: this.state.mappings.map(el =>
              el.id !== message.id ?
                 el : {...el, instances: el.instances.map(inst => { return { ...inst, [message.type + 'State']: message.state, [message.type + 'StartedAt']: message.startedAt, maps: message.maps.map(m => {return{...m, completed:false}})}})}
          )})
        }
      } else if (message.executionInfo) {
        if (message.executionInfo) {
          if (message.instanceId) {
            this.setState({ mappings: this.state.mappings.map(el =>
                el.id !== message.id ?
                   el : {...el, instances: el.instances.map(inst => inst.id === message.instanceId ?
                     { ...inst, [message.type +'State']: message.state,
                    maps: inst.maps ?
                       (inst.maps.some(map => map.triplesMap === message.executionInfo.triplesMap && map.dataSource === message.executionInfo.dataSource) ?
                          inst.maps.map(map => map.triplesMap === message.executionInfo.triplesMap && map.dataSource === message.executionInfo.dataSource ?
                             message.executionInfo : map) : [...inst.maps, message.executionInfo])
                       : [message.executionInfo] } : inst)}
            )});
          } else {
            this.setState({ mappings: this.state.mappings.map(el =>
                el.id !== message.id ?
                   el : {...el, instances: el.instances.map(inst => { return { ...inst, [message.type + 'State']: message.state,  [message.type + 'CompletedAt']: message.completedAt,
                   maps: inst.maps ?
                     (inst.maps.some(map => map.triplesMap === message.executionInfo.triplesMap && map.dataSource === message.executionInfo.dataSource) ?
                        inst.maps.map(map => map.triplesMap === message.executionInfo.triplesMap && map.dataSource === message.executionInfo.dataSource ?
                           message.executionInfo : map) : [...inst.maps, message.executionInfo])
                     : [message.executionInfo]}})}
            )})
          }
        }
      }
    } else if (message.state === "EXECUTED" || message.state === "EXECUTION_FAILED") {
      if (message.instanceId) {
        this.setState({ mappings: this.state.mappings.map(el =>
            el.id !== message.id ?
               el : {...el, instances: el.instances.map(inst => inst.id === message.instanceId ?
                 { ...inst, [message.type +'State']: message.state, [message.type + 'StartedAt']: message.startedAt, [message.type + 'CompletedAt']: message.completedAt} : inst)}
        )});
      } else {
        this.setState({ mappings: this.state.mappings.map(el =>
            el.id !== message.id ?
               el : {...el, instances: el.instances.map(inst => { return { ...inst, [message.type + 'State']: message.state, [message.type + 'StartedAt']: message.startedAt, [message.type + 'CompletedAt']: message.completedAt}})}
        )})
      }
    }
  }


  executeAction(action, params) {
    if (action === 'create-mapping') {
        if (params.id && params.id != null) {
          updateMapping(params.id, params.name, params.json, params.parameters)
            .then(response => {
              this.setState( { mappings: this.state.mappings.map(el => el.id === params.id ? {...el, name:params.name,  d2RML: params.json ? params.json : el.d2RML, parameters : params.parameters } : el ).sort(this.mappingsSort)} );
              this.setState({ newMappingModalShow: false, newMappingModalMapping: null });
            })
        } else {
          createMapping(params.datasetId, this.state.newMappingType, params.name, params.json, params.parameters)
            .then(response => {
              this.setState({ mappings: this.state.mappings.slice().concat(response).sort(this.mappingsSort) })
              this.setState({ newMappingModalShow: false, newMappingModalMapping: null });
          })
        }
    } else if (action === 'create-file') {
      if (params.id && params.id != null) {
        updateFile(params.id, params.name, params.data)
          .then(response => {
           // console.log(response);
           this.setState( { files: this.state.files.map(el => el.id === params.id ? {...el, name:params.name,  fileName: response.fileName } : el ).sort(this.filesSort)} );
       })
      } else {
          createFile(params.name, params.datasetId, params.data)
           .then(response => {
            // console.log(response);
            this.setState({ files: this.state.files.slice().concat(response).sort(this.filesSort) })
        })
      }
      this.fileModal.loadingCompleted();
    } else if (action === 'delete-file') {
      this.setState({ deleteModalShow:true, deleteModalCommand: 'delete-file', deleteModalParams:params})
    } else if (action === 'delete-file-ok') {
      deleteFile(params.id)
        .then(response => {
          this.setState({ files: this.state.files.filter(el => el.id !== params.id)})
        })
    } else if (action === 'save-mapping') {
        // console.log('SAVING');
        // console.log(this.state.mappings[this.state.currentMappingIndex].id);
        // console.log(value);
        // console.log(files);

        var filePromises = [];
        const _this = this;
        for (const i in params.files) {
          if (params.files[i].blob !== undefined) {
            filePromises.push(
              new Promise(function(resolve, reject) {
                 uploadMappingAuxiliaryFile(_this.state.mappings[_this.state.currentMappingIndex].id, params.files[i].name, params.files[i].blob)
                    .then(resolve())
                  }
                ))
              }
        }

        Promise.all(filePromises).then(() => {
            updateMapping(this.state.mappings[this.state.currentMappingIndex].id, this.state.mappings[this.state.currentMappingIndex].id.name, JSON.stringify(params.d2rml))
            // .then(() =>  {
            //    this.setState(copyReplaceAt('D2RMLMappings', this.state.mappings, this.state.currentIndex, { ...this.state.mappings[this.state.currentIndex], d2RML: JSON.stringify(value)}))
            // })
        })


    } else if (action === 'execute-mapping') {
        // console.log(this.state.mappings);
        executeMapping(params.id, params.instanceId)
           .then(() => {
              // if (params.instanceId) {
              //   this.setState({ mappings: this.state.mappings.map(el =>
              //       el.id !== params.id ?
              //          el : {...el, instances: el.instances.map(inst => inst.id === params.instanceId ?
              //            { ...inst, executeState: 'EXECUTING', executeStartedAt: new Date(), executeCompletedAt: null} : inst)}
              //   )})
              // } else {
              //
              //   this.setState({ mappings: this.state.mappings.map(el =>
              //       el.id !== params.id ?
              //          el : {...el, instances: el.instances.map(inst => {return { ...inst, executeState: 'EXECUTING', executeStartedAt: new Date(), executeCompletedAt: null}})}
              //   )})
              // }
            }
         )
   } else if (action === 'clear-mapping-execution') {
       // console.log(this.state.mappings);
       clearMappingExecution(params.id, params.instanceId)
          .then(() => {
            if (params.instanceId) {
              this.setState({ mappings: this.state.mappings.map(el =>
                  el.id !== params.id ?
                     el : {...el, instances: el.instances.map(inst => inst.id === params.instanceId ?
                       { ...inst, executeState: null, executeStartedAt: null, executeCompletedAt: null, maps: null} : inst)}
              )});
            } else {
              this.setState({ mappings: this.state.mappings.map(el =>
                  el.id !== params.id ?
                     el : {...el, instances: el.instances.map(inst => { return { ...inst, executeState: null, executeStartedAt: null, executeCompletedAt: null, maps: null}})}
              )})
            }
             // if (params.instanceId) {
             //   this.setState({ mappings: this.state.mappings.map(el =>
             //       el.id !== params.id ?
             //          el : {...el, instances: el.instances.map(inst => inst.id === params.instanceId ?
             //            { ...inst, executeState: 'EXECUTING', executeStartedAt: new Date(), executeCompletedAt: null} : inst)}
             //   )})
             // } else {
             //
             //   this.setState({ mappings: this.state.mappings.map(el =>
             //       el.id !== params.id ?
             //          el : {...el, instances: el.instances.map(inst => {return { ...inst, executeState: 'EXECUTING', executeStartedAt: new Date(), executeCompletedAt: null}})}
             //   )})
             // }
           }
        )
    } else if (action === 'download-d2rml') {
        downloadMapping(params.id)
    // } else if (action === 'update-name') {
      // console.log(this.state.currentIndex);
      // console.log(value);
      // var currentMapping = {...this.state.mappings[this.state.currentMappingIndex], name:value };
      //
      // this.setState(copyReplaceAt('D2RMLMappings', this.state.mappings, this.state.currentIndex, currentMapping));
    } else if (action === 'delete-mapping') {
        this.setState({ deleteModalShow:true, deleteModalCommand: 'delete-mapping', deleteModalParams:params})
    } else if (action === 'delete-mapping-ok') {
      deleteMapping(params.id)
        .then(() =>
           // this.setState({ mappings: splice(this.state.mappings, this.state.currentMappingIndex), currentMappingIndex: null}))
           this.setState({ mappings: this.state.mappings.filter(el => el.id !== params.id), currentMappingIndex: null}))
    } else if (action === 'view-mapping-execution') {
        this.setState({ lastExecution: null, resultsShow:true, resultsState: { loaded: false, loading: true, failed: false}}, () =>
          viewMappingExecution(params.id, params.instanceId)
            .then(response => {
              response.text().then(text =>
                this.setState({ lastExecution: { text: text }, resultsState: {loaded: true, loading: false, failed: false} }))},
                () => this.setState({ lastExecution: null, resultsState: {loaded: false, loading: false, failed: true}}) )
          )
      } else if (action === 'download-mapping-execution') {
          downloadMappingExecution(params.id, params.instanceId)
          .then((response) => {
            if (response.ok) {
              const filename = response.headers.get('content-disposition')
                .split(';')
                .find(n => n.includes('filename='))
                .replace('filename=', '')
                .trim();

                response.blob().then(blob => {
            					let url = window.URL.createObjectURL(blob);
            					let a = document.createElement('a');
            					a.href = url;
            					a.download = filename;
            					a.click();
            				});
              } else {
                 Promise.reject(response)
              }},
              () => Promise.reject())
      } else if (action === 'view-prefix-mapping') {
          // this.setState({ lastExecution: null, resultsShow:true, resultsState: { loaded: false, loading: true, failed: false}}, () =>
          //   viewPrefixMapping(params.id)
          //     .then(response => {
          //       response.text().then(text =>
          //         this.setState({ lastExecution: { text: text }, resultsState: {loaded: true, loading: false, failed: false} }))},
          //         () => this.setState({ lastExecution: null, resultsState: {loaded: false, loading: false, failed: true}}) )
          //   )
      } else if (action === 'view-file') {
          this.setState({ lastExecution: null, resultsShow:true, resultsState: { loaded: false, loading: true, failed: false}}, () =>
          viewFile(params.id)
            .then(response => {
              response.text().then(text =>
                this.setState({ lastExecution: { text: text }, resultsState: {loaded: true, loading: false, failed: false} }))},
                () => this.setState({ lastExecution: null, resultsState: {loaded: false, loading: false, failed: true}}) )
          )
      } else if (action === 'add-parameter-binding') {
          this.setState({parameters: this.state.mappings[params.index].parameters, mappingId:params.id, newBindingModalShow:true})
      } else if (action === 'create-parameter-binding') {
          createMappingParameterBinding(params.id, params.parameters)
            .then((json) =>
              this.setState({ mappings: this.state.mappings.map(el =>
                  el.id !== params.id ?
                     el : {...el, instances: el.instances.concat(json)}),
                   newBindingModalShow:false})
          )
      } else if (action === 'delete-mapping-binding') {
          this.setState({ deleteModalShow:true, deleteModalCommand: 'delete-mapping-binding', deleteModalParams:params})
      } else if (action === 'delete-mapping-binding-ok') {
          deleteMappingParameterBinding(params.id, params.instanceId)
            .then(() => this.setState({ mappings: this.state.mappings.map(el =>
                el.id !== params.id ?
                   el : {...el, instances: el.instances.filter(inst => inst.id !== params.instanceId)})})
          )
      } else if (action === 'update-mapping') {
        this.setState({ newMappingModalShow: true, newMappingType: params.mapping.type, newMappingModalMapping: params.mapping})
      } else if (action === 'update-file') {
        this.setState({ newDataModalShow: true, newDataModalFile: params.file})
      }
    }

  selectMapping(index) {
    this.setState({ currentMappingIndex: index })
  }

  isPrefixMapping(el) {
    return el.type === 'PREFIX';
  }

  render() {

    var currentMapping = null;
    if (this.state.currentMappingIndex !== null) {
      currentMapping = this.state.mappings[this.state.currentMappingIndex]
    }

    var published = this.props.dataset.publishState === "PUBLISHED_PUBLIC" || this.props.dataset.publishState === "PUBLISHED_PRIVATE";
    var indexed = this.props.dataset.indexState === "INDEXED";
    // console.log("DATASET")
    // console.log(this.props)

    return (
      <Container>
        <Row>
          <Col>
            <Container className="groupborder">
              <Row className="header">
{/*              <Col className="mybutton"  md="auto">
                <span className='fa fa-bullseye close editbutton'></span>
              </Col>
              <Col>
                <Form.Control value={this.props.dataset.name} plaintext className="bold nospace2"
                              onChange={(event) => this.props.actions('update-name', event.target.value)}/>
              </Col>*/}
              <Col className="bold">
                {this.props.dataset.name}
              </Col>
              <Col md="auto" className="datasetId">
                {this.props.dataset.uuid}
              </Col>
              <Col className="mybutton" md="auto">
            {this.state.newCatalogLinkModalShow &&
              <NewDatasetLinkModal show={this.state.newCatalogLinkModalShow}
                          datasets={this.props.datasets}
                           onOK={(id) => {this.addDataset(id, this.props.dataset.id); this.setState({ newCatalogLinkModalShow: false})}}
                           onClose={() => this.setState({ newCatalogLinkModalShow: false})}/>}
                <DropdownButton size="sm" title="Actions" className="actions">
                  <Dropdown.Item onClick={() => this.props.actions('update-dataset', {id : this.props.dataset.id, type: this.props.type, scope: this.props.scope})}>Edit</Dropdown.Item>
                  <Dropdown.Item disabled={!(!published && !indexed && (this.props.type==='dataset' || (this.props.type === 'catalog' && this.props.dataset.datasets.length === 0)))} onClick={() => this.props.actions('delete-dataset', {id: this.props.dataset.id, type: this.props.type})}>Delete</Dropdown.Item >
                  <Dropdown.Divider/>
                  <Dropdown.Divider/>
                  {this.props.type === 'dataset' && <Dropdown.Item  onClick={(event) => this.setState({ newDataModalShow: true})}>Add Content File</Dropdown.Item>}
                  {this.props.type === 'dataset' && <Dropdown.Item  onClick={(event) => this.setState({ newMappingModalShow: true, newMappingType: 'CONTENT', newMappingModalMapping: null})}>Add Content Mapping</Dropdown.Item>}
                  {this.props.metadata && <Dropdown.Item  onClick={(event) => this.setState({ newMappingModalShow: true, newMappingType: 'HEADER', newMappingModalMapping:null})}>Add Metadata Mapping</Dropdown.Item>}
                  {this.props.type === 'dataset' && <Dropdown.Item  onClick={(event) => this.setState({ newMappingModalShow: true, newMappingType: 'PREFIX', newMappingModalMapping: null})}>Add Prefix File</Dropdown.Item>}
                  <Dropdown.Divider/>
                  {this.props.type === 'catalog' &&
                  <Dropdown.Item  onClick={() => this.setState({ newCatalogLinkModalShow: true})}>Add Dataset</Dropdown.Item>}
                  {this.props.type === 'catalog' && <Dropdown.Divider/>}
                  {!published && <Dropdown.Item  onClick={(event) => this.setState({ publishModalShow: true})}>Publish</Dropdown.Item>}
                  {published && <Dropdown.Item  onClick={(event) => this.props.actions('publish-unpublished-content', {id : this.props.dataset.id})}>Publish Unpublished Content</Dropdown.Item>}
                  {(true || this.props.metadata) && (published || !published) && <Dropdown.Item  onClick={(event) => this.props.actions('republish-dataset-metadata', {id : this.props.dataset.id})}>Republish Metadata</Dropdown.Item>}
                  <Dropdown.Item  onClick={(event) => this.props.actions('unpublish-dataset', {id : this.props.dataset.id, type:this.props.type})}>Unpublish</Dropdown.Item>
                  {this.props.metadata && published && <Dropdown.Item  onClick={(event) => this.props.actions('flip-dataset-visibility', {id : this.props.dataset.id})}>Flip Visibility</Dropdown.Item>}
                  <Dropdown.Divider/>
                  {this.props.type === 'dataset' &&
                  <Dropdown.Item  disabled={!published || indexed} onClick={(event) => this.props.actions('index-dataset', {id : this.props.dataset.id})}>Index</Dropdown.Item>}
                  {this.props.type === 'dataset' &&
                  <Dropdown.Item  disabled={!indexed} onClick={(event) => this.props.actions('unindex-dataset', {id : this.props.dataset.id})}>Unindex</Dropdown.Item>}
                  </DropdownButton>

                {this.state.newMappingModalShow &&
                  <NewMappingUploadModal show={this.state.newMappingModalShow}
                            type={this.state.newMappingType}
                            mapping={this.state.newMappingModalMapping}
                            uuid={this.props.dataset.uuid}
                            onOK={(id, name, json, parameters)=> this.executeAction('create-mapping', {datasetId: this.props.dataset.id, id: id, name: name, json: json, parameters: parameters})}
                            onClose={() => this.setState({ newMappingModalShow: false, newMappingType: undefined })}/>}

                {this.state.newDataModalShow &&
                  <NewDataUploadModal show={this.state.newDataModalShow}
                            uuid={this.props.dataset.uuid}
                            file={this.state.newDataModalFile}
                            ref={node => (this.fileModal = node)}
                            onOK={(id, name, data) => this.executeAction('create-file', {datasetId: this.props.dataset.id, id: id, name: name, data:data})}
                            onClose={() => this.setState({ newDataModalShow: false })}/>}

              {this.state.publishModalShow &&
                <PublishModal show={this.state.publishModalShow} value={this.props.dataset} allowPublic={this.props.user.type === "ADMIN"}
                             visibility={this.props.visibility}
                             onOK={(privat) => {this.props.actions('publish-dataset', {id: this.props.dataset.id, visibility: privat, type: this.props.type}); this.setState({ publishModalShow: false })}}
                             onClose={() => this.setState({ publishModalShow: false })}/>}

              </Col>
{/*              {(this.props.dataset.publishState === "UNPUBLISHED" && this.props.dataset.indexState === "NOT_INDEXED" && (this.props.type==='dataset' || (this.props.type === 'catalog' && this.props.dataset.datasets.length === 0))) &&
              <Col className="mybutton" md="auto">
                  <Button type="button" className="menubutton" aria-label="Delete" onClick={() => this.props.actions('delete-dataset', {id: this.props.dataset.id, type: this.props.type})}><span className="fa fa-trash"></span></Button>
              </Col>}*/}

              </Row>
{/*              <Row className="tableheader">
                <Col>
                  <span>Dataset ID</span>
                </Col>
                <Col>
                  {this.props.dataset.uuid}
                </Col>
              </Row> */}
              <Row className="tableheader">
                <Col>
                  <span>Status</span>
                </Col>
                <Col>
                <div>
                  <Row>
                    <Col>
                      <PublishableState value={this.props.dataset}/>
                    </Col>
                  </Row>
                  {this.props.type === 'dataset' &&
                  <Row>
                    <Col>
                      <IndexableState value={this.props.dataset}/>
                    </Col>
                  </Row>}
                </div>
                </Col>
              </Row>

            {this.props.type === 'catalog' &&
              <Row className="tableheader">
                <Col>
                  <span>Members</span>
                </Col>
              </Row>
              }
              {this.props.type === 'catalog' && this.props.dataset.datasets.map((el, index) =>
              <Row key={index}>
                <Col>
                  {el.name}
                </Col>
                <Col md="auto">
                  <Button type="button" className="menubutton" aria-label="Delete" onClick={() => this.removeDataset(el.id, this.props.dataset.id)}><span className="fa fa-trash"></span></Button>
                </Col>
              </Row>
              )}
              {this.props.type === 'catalog' &&
              <Row className="tableheader"></Row>}

              {this.state.mappings.length > 0 &&
              <Row className="tableheader">
                <Col>
                  <span>Mapping</span>
                </Col>
                <Col md={1}>
                </Col>
                <Col md={1} className="tablecenter">
                  <span>Type</span>
                </Col>
                <Col md={5} className="tablecenter">
                  <span>State</span>
                </Col>
                <Col className="mybutton" md={3}></Col>
              </Row>}


              {this.state.mappings.map((el, index) =>
                el.parameters.length === 0 ?
                <Row key={index} className="mappingRow">
                  <Col>
                    <span className="abutton alink" onClick={() => this.selectMapping(index)}>{el.name}</span>
                  </Col>
                  <Col className="mybutton" md={1}>
                  <DropdownButton size="sm" title="" className="actions">
                    <Dropdown.Item onClick={() => this.executeAction('update-mapping', {mapping: el})}>Edit</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.executeAction('delete-mapping', {id: el.id})}>Delete</Dropdown.Item >
                  </DropdownButton>
                  </Col>
                  <Col md={1} className="tablecenter mappingtype">
                    <span>{el.type === "HEADER" ? "META" : (el.type === "CONTENT" ? "DATA" : "PRFX")}</span>
                  </Col>
                  <Col md={5} className="tablecenter">
                    {!this.isPrefixMapping(el) ?
                    <div  className="stategroup">
                      <Row>
                        <Col>
                          <ExecutableState value={el.instances[0]}/>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <PublishableState value={el.instances[0]}/>
                        </Col>
                      </Row>
                    </div> :
                    <div/>
                  }
                  </Col>
                  <Col md="3">
                  {!this.isPrefixMapping(el) ?
                    <Row>
                      <Col className="mybutton" md="auto">
                          {/*<Button type="button" disabled={el.instances[0].executeState !== 'EXECUTED'} className={el.instances[0].executeState !== 'EXECUTED' ? 'menubutton disabled' : 'menubutton'} aria-label="Execute" onClick={() => this.executeAction('execute-mapping', {id: el.id})}><span className="fa fa-play-circle"></span></Button>*/}
                          <Button type="button" className='menubutton' aria-label="Execute" onClick={() => this.executeAction('execute-mapping', {id: el.id})}><span className="fa fa-play-circle"></span></Button>
                      </Col>

                      <Col className="mybutton" md="auto">
                          <Button type="button" disabled={el.instances[0].executeState !== 'EXECUTED'} className="menubutton"  aria-label="View last execution" onClick={() => this.executeAction('view-mapping-execution', {id: el.id})}><span className="fa fa-eye"></span></Button>
                      </Col>
                      <Col className="mybutton" md="auto">
                          <Button type="button" disabled={el.instances[0].executeState !== 'EXECUTED'} className="menubutton" aria-label="Download last execution" onClick={() => this.executeAction('download-mapping-execution', {id: el.id})}><span className="fa fa-download"></span></Button>
                      </Col>
                      <Col className="mybutton" md="auto">
                          <Button type="button" disabled={el.instances[0].executeState !== 'EXECUTED' || el.instances[0].publishState === 'PUBLISHED'} className="menubutton" aria-label="Clear execution" onClick={() => this.executeAction('clear-mapping-execution', {id: el.id})}><span className="fa fa-times"></span></Button>
                      </Col>
                    </Row> :
                    <Row/>
                  }
                  </Col>
                </Row>
                :

                <Row key={index} ><Col className="mappingRow">
                <Row key={index}>
                  <Col>
                    <span className="abutton alink" onClick={() => this.selectMapping(index)}>{el.name}</span>
                  </Col>
                  <Col className="mybutton" md={1}>
                  <DropdownButton size="sm" title="" className="actions">
                    <Dropdown.Item onClick={() => this.executeAction('update-mapping', {mapping: el})}>Edit</Dropdown.Item>
                    <Dropdown.Item onClick={() => this.executeAction('delete-mapping', {id: el.id})}>Delete</Dropdown.Item >
                    <Dropdown.Item onClick={() => this.executeAction('add-parameter-binding', {id: el.id, index: index})}>Add instance</Dropdown.Item >
                  </DropdownButton>
                  </Col>
                  <Col md={1} className="tablecenter mappingtype">
                    <span>{el.type === "HEADER" ? "META" : "DATA"}</span>
                  </Col>
                  <Col md="8"/>
                </Row>
                <div className="mappingPadding">
                {el.instances.map((inst, index) =>
                  <Row key={index} className="mappingInstanceRow">
                    <Col>
                    {inst.binding.map((binding, bindex) =>
                        <Row key={bindex} className="border">
                          <Col md="auto">
                            {binding.name}: <span className="red">{binding.value}</span>
                          </Col>
                        </Row>
                    )}
                    </Col>
                    <Col className="mybutton" md={1}>
                    <DropdownButton size="sm" title="" className="actions">
                      {/* <Dropdown.Item onClick={() => this.executeAction('update-mapping', {mapping: el})}>Edit</Dropdown.Item>*/}
                      <Dropdown.Item onClick={() => this.executeAction('delete-mapping-binding', {id: el.id, instanceId: inst.id, index:index})}>Delete</Dropdown.Item >
                    </DropdownButton>
                    </Col>
                    <Col md="3" className="tablecenter">
                      <div>
                        <Row>
                          <Col>
                            <ExecutableState value={inst}/>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <PublishableState value={inst}/>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col md="3">
                      <Row>
                        <Col className="mybutton" md="auto">
                            <Button type="button" className="menubutton" aria-label="Execute" onClick={() => this.executeAction('execute-mapping', {id: el.id, instanceId: inst.id})}><span className="fa fa-play-circle"></span></Button>
                        </Col>
                        <Col className="mybutton" md="auto">
                            <Button type="button" disabled={inst.executeState !== 'EXECUTED'} className="menubutton" aria-label="View last execution" onClick={() => this.executeAction('view-mapping-execution', {id: el.id, instanceId: inst.id})}><span className="fa fa-eye"></span></Button>
                        </Col>
                        <Col className="mybutton" md="auto">
                            <Button type="button" disabled={inst.executeState !== 'EXECUTED'} className="menubutton" aria-label="Download last execution" onClick={() => this.executeAction('download-mapping-execution', {id: el.id, instanceId: inst.id})}><span className="fa fa-download"></span></Button>
                        </Col>
                        <Col className="mybutton" md="auto">
                            <Button type="button" disabled={inst.executeState !== 'EXECUTED'} className="menubutton" aria-label="Clear execution" onClick={() => this.executeAction('clear-mapping-execution', {id: el.id, instanceId: inst.id})}><span className="fa fa-times"></span></Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}</div>
                </Col></Row>
              )}

              {this.state.files.length > 0 &&
              <Row className="tableheader"></Row>}

              {this.state.files.length > 0 &&
              <Row className="tableheader">
                <Col md={3}>
                  <span>File</span>
                </Col>
                <Col md={3}>
                  <span>Filename</span>
                </Col>
                <Col md={5} className="tablecenter">
                  <span>State</span>
                </Col>
                <Col md={2}/>
              </Row>}

              {this.state.files.map((el, index) =>
              <Row key={index}>
                <Col>
                  <span className="abutton">{el.name}</span>
                </Col>
                <Col className="mybutton" md={1}>
                <DropdownButton size="sm" title="" className="actions">
                  <Dropdown.Item onClick={() => this.executeAction('update-file', {file: el})}>Edit</Dropdown.Item>
                  <Dropdown.Item onClick={() => this.executeAction('delete-file', {id: el.id})}>Delete</Dropdown.Item >
                </DropdownButton>
                </Col>
                <Col md={3} className="tablecenter">
                {el.fileName ? el.fileName : '' }
                </Col>
                <Col md={5} className="tablecenter">
                  <div>
                    <Row>
                      <Col>
                        <PublishableState value={el}/>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={1}>
                  <Row>
                    <Col className="mybutton" md="auto">
                        <Button type="button" className="menubutton" aria-label="View contents" onClick={() => this.executeAction('view-file', {id: el.id})}><span className="fa fa-eye"></span></Button>
                    </Col>
                  </Row>
                </Col>
              </Row>)}
            </Container>

            <NewBindingModal show={this.state.newBindingModalShow} mapping={this.state.mappingId} parameters={this.state.parameters}
                         onOK={(id, params)=> this.executeAction('create-parameter-binding', {id: id, parameters: params})}
                         onClose={() => this.setState({ newBindingModalShow: false })}/>


            {currentMapping &&
            <Container className="groupborder">
              <D2RMLEditor value={currentMapping}
                   fixedSubject={currentMapping.type==='HEADER'?true:false} delete={currentMapping.parameters.length === 0 || currentMapping.instances.length === 0}
                   actions={(action, value, files) => this.executeAction(action, value, files)}/>
            </Container>}

          </Col>
        </Row>

        {this.state.deleteModalShow &&
          <DeleteModal show={this.state.deleteModalShow}
                       command={this.state.deleteModalCommand}
                       params={this.state.deleteModalParams}
                       actions={(choice, command, params) => { this.setState( {deleteModalShow:false }); return (choice === 'ok' ? this.executeAction(command + '-ok', params): null)} }/>}

        {this.state.resultsShow &&
          <ResultsModal show={this.state.resultsShow}
                        state={this.state.resultsState}
                        execution={this.state.lastExecution}
                        onClose={() => this.setState({resultsShow:false})}/>}

      </Container>
    );
  }
}

export default DatasetEditor;
