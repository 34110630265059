import React, { Component } from "react";

import { TIME_OPTIONS } from "../../constants/index.js"

export class PublishableState extends Component {

  render() {
    var cz
    var ct
    if (this.props.value.publishState === 'UNPUBLISHED') {
      cz = 'not-executed'
      ct = 'Not published'
    } else if (this.props.value.publishState === 'PUBLISHING') {
      cz = 'executing'
      ct = 'Publishing (since ' + new Date(this.props.value.publishStartedAt).toLocaleDateString('en-UK', TIME_OPTIONS) + ')';
    } else if (this.props.value.publishState === 'UNPUBLISHING') {
      cz = 'executing'
      ct = 'Unpublishing (since ' + new Date(this.props.value.publishStartedAt).toLocaleDateString('en-UK', TIME_OPTIONS) + ')';
    } else if (this.props.value.publishState === 'PUBLISHED_PRIVATE') {
      cz = 'executed'
      ct = 'Published private (at ' + new Date(this.props.value.publishCompletedAt).toLocaleDateString('en-UK', TIME_OPTIONS) + ')'
    } else if (this.props.value.publishState === 'PUBLISHED_PUBLIC') {
      cz = 'executed'
      ct = 'Published public (at ' + new Date(this.props.value.publishCompletedAt).toLocaleDateString('en-UK', TIME_OPTIONS) + ')'
    } else if (this.props.value.publishState === 'PUBLISHED') {
      cz = 'executed'
      ct = 'Published (at ' + new Date(this.props.value.publishCompletedAt).toLocaleDateString('en-UK', TIME_OPTIONS) + ')'
    } else if (this.props.value.publishState === 'PUBLISHING_FAILED') {
      cz = 'failed'
      ct = 'Publishing failed'
    } else {
      cz = 'not-executed'
      ct = 'Not published'      
    }

    return (
      <div className={cz}>
        {ct}
      </div>
    )
  }
}

export default PublishableState;
