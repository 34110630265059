import React, { Component } from "react";
import Header from "./Header.js";
import Container from "react-bootstrap/Container";
import {Switch, Route, Link} from 'react-router-dom';
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import Search from "./explore/Search.js";
import UserSpace from "./api/UserSpace.js";
import SignInModal from "./SignInModal.js";
import { getCurrentUser, getCurrentDatabase } from '../utils/APIUtils.js';
import {signin } from "../utils/APIUtils.js"
import { ACCESS_TOKEN } from '../constants/index.js';
// import { filterByLanguage } from "../utils/functions.js";

import { isEqual } from 'lodash';

import { Localizer } from '../config/localizer.js'

export class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      language: "en",
      logInShow: false,

      currentUser: null,
      isAuthenticated: false,
      isLoading: false,

      database: "",
    }
  }

  componentDidMount() {
    document.title = Localizer.semantic_network[this.state.language]

    getCurrentDatabase()
      .then(response => {
        this.setState({ database: response});
        document.title = Localizer.semantic_network[this.state.language] + ": " + this.state.database.label;
      })

    // signin({ username: '', password: '' })
    //   .then(response => {
    //     localStorage.setItem(ACCESS_TOKEN, response.accessToken);
    //     this.handleSignIn()
    //   })
  }

  handleSignIn() {
    this.setState({ isLoading: true });

    getCurrentUser()
      .then(response => {
        this.setState({
          currentUser: response,
          isAuthenticated: true,
          isLoading: false,
          logInShow: false
        })
      })
  }

  handleSignOut() {
    localStorage.removeItem(ACCESS_TOKEN);

    this.setState({
      currentUser: null,
      isAuthenticated: false,
      datasets: [],
      rootDataset: null
    });
  }

  executeAction(action, params) {
    if (action === 'change-language') {
      this.changeLanguage(params.language);
    } else if (action === 'sign-button-action') {
      if (!this.state.isAuthenticated) {
        this.setState({ logInShow: true});
      } else {
        this.handleSignOut();
      }
    } else if (action === 'sign-in') {
      this.handleSignIn();
    } else if (action === 'cancel-sign-in') {
      this.setState({ logInShow: false});
    } else if (action === 'set-root-dataset') {
      if (!this.state.rootDataset && !isEqual(this.state.rootDataset, params.dataset)) { // childern-parent loop is this condition is removed.
        this.setState({ rootDataset: params.dataset })
      }
    }
}

  changeLanguage(language) {
    this.setState({language});
  }


  renderManage() {
    return (
      this.state.isAuthenticated &&
        <UserSpace user={this.state.currentUser}
                   language={this.state.language}/>
    )
  }

  renderWelcome() {
    return (
      <Container>
        <Row className="welcometable appheader">
          <Col/>
          <Col md="3">
            <Link to="/search"><span className="welcome">> {Localizer.explore[this.state.language]}</span></Link>
          </Col>
          <Col md="3">
            <Link to="/manage"><span className="welcome">> {Localizer.manage[this.state.language]}</span></Link>
          </Col>
          <Col/>
        </Row>
      </Container>
    )
  }

  render() {
    return (
      <Container fluid>
        <Header language={this.state.language}
                database={this.state.database}
                root={this.state.rootDataset}
                user={this.state.currentUser}
                actions={(action, params) => this.executeAction(action, params)}/>

        {this.state.logInShow &&
          <SignInModal show={this.state.logInShow}
                       onSignIn={()=> this.executeAction('sign-in')}
                       onClose={() => this.executeAction('cancel-sign-in')}/>}

        <Switch>
          <Route path="/search/:rootDataset" exactly
                 render={props =>
                   <Search {...props}
                           user={this.state.currentUser}
                           language={this.state.language}
                           database={this.state.database}
                           actions={(action, params) => this.executeAction(action,params)}/>}/>
          <Route path="/search" exactly
                 render={props =>
                   <Search {...props}
                           user={this.state.currentUser}
                           language={this.state.language}
                           database={this.state.database}
                           actions={(action, params) => this.executeAction(action,params)}/>}/>
          <Route path="/manage" exactly
                 render={() => this.renderManage()}/>
          <Route path="/" exactly
                 render={() => this.renderWelcome()}>
          </Route>
        </Switch>

      </Container>
  )}
}

export default App
