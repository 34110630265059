export function splice(array, index) {
  return array.slice(0,index).concat(array.slice(index+1))
}

export function checkStatus (res) {
  if (res.status >= 200 && res.status < 300) {
    return res
  } else {
    let err = new Error(res.statusText)
    err.response = res
    throw err
  }
}


export function buildQueryString(params) {
  return Object.keys(params).map(key => key + '=' + params[key]).join('&');
}

export function copyReplaceAt(prop, array, index, object) {
  if (object !== null) {
    return { [prop]: array.slice(0, index)
                         .concat(object)
                         .concat(array.slice(index + 1)) }
   } else {
     return { [prop]: array.slice(0, index)
                          .concat(array.slice(index + 1)) }
   }
}

export function filterByLanguage(data, property, language) {

  if (!data[property]) {
    return null;
  }
  for (const item in data[property]) {
    if (data[property][item]['@language'] !== undefined && (data[property][item]['@language'] === language || data[property][item]['@language'].startsWith(language + '-'))) {
        return data[property][item]['@value'];
    }
  }

  for (const item in data[property]) {
    if (data[property][item]['@language'] !== undefined && (data[property][item]['@language'] === 'en' || data[property][item]['@language'].startsWith('en-'))) {
        return data[property][item]['@value'];
    }
  }

  return data[property][0]['@value'];
}
