import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { filterByLanguage, copyReplaceAt } from '../../../utils/functions.js'

export class AnnotatorModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      annotator: null,
      preprocess: []
      // functionParameters: []
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();
    var params = [];
    for (const i in this.state.annotator.parameters) {
      params.push({ name: this.state.annotator.parameters[i].name, value: this['param-' + this.state.annotator.parameters[i].name].value})
    }

    var pp = this.state.preprocess.map(el => { return { function: el.function.uri, parameters: el.parameters}})
    this.props.onOK(this.property.value, this.annotator.value, this.thesaurus ? this.thesaurus.value: null, params, pp, this.variant ? this.variant.value : null);
  }

  annotatorChanged() {
    this.setState( { annotator : this.props.dataAnnotators.filter(el => el.identifier === this.annotator.value)[0]}, () => {

    this.property.value = this.state.annotator.asProperties && this.state.annotator.asProperties.length === 1 ? this.state.annotator.asProperties[0]:''
  })
  }

  thesaurusChanged() {
     this.setState( { thesaurus : this.props.vocabularies.filter(el => el['http://purl.org/dc/elements/1.1/identifier'][0]['@id'] === this.thesaurus.value)[0] } )
  }

  addPreprocess() {
    this.setState({ preprocess: this.state.preprocess.slice().concat({ function : '', parameters: []}) });
  }

  deletePreprocess(index) {
    // console.log( this.state.preprocess.slice(0, index).concat(this.state.preprocess.slice(index + 1)));
    this.setState({ preprocess: this.state.preprocess.slice(0, index).concat(this.state.preprocess.slice(index + 1)) })
  }

  functionChanged(index, event) {
      var f = this.props.preprocessFunctions.filter(el => el.uri === event.target.value)[0];
      var fp = [];
      for (var i in f.parameters) {
        if (f.parameters[i] !== "input") {
          fp.push({name: f.parameters[i]})
        }
      }
      this.setState(copyReplaceAt('preprocess', this.state.preprocess, index, { function: f, parameters: fp }))
  }

  functionParameterChanged(index, parameter, event) {
    var obj = this.state.preprocess[index];
    var pindex = obj.parameters.findIndex(el => el.name === parameter.name);

    var np = obj.parameters.slice(0, pindex).concat({ name: parameter.name, value: event.target.value}).concat(obj.parameters.slice(pindex + 1));
    this.setState(copyReplaceAt('preprocess', this.state.preprocess, index, { function: obj.function, parameters: np }))

  }

  jsonldsort(a,b) {
    if (a['@value'] !== undefined && b['@value'] !== undefined) {
      if (a['@value'] < b['@value']) {
        return -1;
      } else if (a['@value'] > b['@value']) {
        return 1;
      } else {
        return 0;
      }
    }  else if (a['@id'] !== undefined && b['@id'] !== undefined) {
      if (a['@id'] < b['@id']) {
        return -1;
      } else if (a['@id'] > b['@id']) {
        return 1;
      } else {
        return 0;
      }
    }
  }

  render() {
     // console.log(this.props.preprocessFunctions);

    return (
      <Modal show={this.props.show} onHide={this.props.onClose} animation={false} backdrop="static">
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header>
            <Modal.Title>New Annotator</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Group>
              <Form.Label className="bold">Annotator</Form.Label>
              <Form.Control as="select" ref={node => (this.annotator = node)}
                            onChange={() => this.annotatorChanged()}
                            defaultValue=''
                            required>
                  <option hidden disabled value=''> -- Select an annotator -- </option>
                  {this.props.dataAnnotators.map((el, index) =>
                    <option key={index} value={el.identifier}>{el.title}</option>
                  )}
              </Form.Control>
            </Form.Group>

            {this.state.annotator && this.state.annotator.variants.length > 1 &&
              <Form.Group>
              <Form.Label>Variant</Form.Label>
              <Form.Control as="select" ref={node => (this.variant = node)}
                            defaultValue=''
                            required>
                <option hidden disabled value=''> -- Select a variant -- </option>
                {this.state.annotator && this.state.annotator.variants.map((el, index) =>
                  <option key={index} value={el.name}>{el.name}</option>
                )}
              </Form.Control>
            </Form.Group>}

            <Form.Group>
              <Form.Label>View as</Form.Label>
              <Form.Control as="select" ref={node => (this.property = node)}
                            defaultValue=''
                            required>
                <option hidden disabled value=''> -- Select a property -- </option>
                {this.state.annotator && this.state.annotator.asProperties && this.state.annotator.asProperties.map((el, index) =>
                  <option key={index} value={el}>{el}</option>
                )}
              </Form.Control>
            </Form.Group>
            {this.state.annotator && this.state.annotator.identifier === 'inthesaurus' &&
            <Form.Group>
              <Form.Label>Thesaurus</Form.Label>
              <Form.Control as="select" ref={node => (this.thesaurus = node)}
                            onChange={() => this.thesaurusChanged()}
                            defaultValue=''
                            required>
                  <option hidden disabled value=''> -- Select a thesaurus -- </option>
                  {this.props.vocabularies.map((el, index) =>
                     <option key={index} value={el['http://purl.org/dc/elements/1.1/identifier'][0]['@id']}>{filterByLanguage(el  ,'http://www.w3.org/2000/01/rdf-schema#label', 'el')}</option>)}
              </Form.Control>
            </Form.Group>}
            {this.state.annotator && this.state.annotator.parameters && this.state.annotator.parameters.map((el,index) =>
              <Form.Group key={index}>
                <Form.Label>{el.name}</Form.Label>
                {el.values.length === 0 && (el.type === "text" ?
                  <Form.Control as="textarea" ref={node => (this['param-' + el.name] = node)}/> :
                  <Form.Control ref={node => (this['param-' + el.name] = node)}/>)
                }
                {el.values.length > 0 &&
                <Form.Control as="select" ref={node => (this['param-' + el.name] = node)}
                              onChange={() => this.annotatorChanged()}
                              defaultValue={el.defaultValue}
                              required>
                    <option hidden disabled value=''> -- Select a value -- </option>
                    {el.values.map((v, index) => {
                      var matches = v.match("^\\{(.*)\\}$")
                      if (matches) {
                        if (this.state.thesaurus && this.state.thesaurus[matches[1]]) {
                         return this.state.thesaurus[matches[1]].sort(this.jsonldsort).map((t,id) => {return <option key={index + "_" + id} value={t['@value'] ? t['@value'] : t['@id']}>{t['@value'] ? t['@value'] : t['@id']}</option>})
                       } else {
                         return '';
                       }
                     } else {
                      return <option key={index} value={v}>{v}</option> }
                    })}
                </Form.Control>}
              </Form.Group>
            )}
            <Row className="tableheader">
            </Row>

            <Form.Group>
              <Form.Label className="bold">Preprocess</Form.Label>
              <Button type="button" className="deleteaddbutton" aria-label="New" onClick={(event) => this.addPreprocess(event)}><span className="fa fa-plus"></span></Button>
            </Form.Group>

          {this.state.preprocess.map((el, index) =>
            <Row>
            <Col>
            <Form.Group>
              <Form.Label>Function</Form.Label>
              <Form.Control as="select"
                            onChange={(event) => this.functionChanged(index, event)}
                            defaultValue=''>
                  <option hidden disabled value=''> -- Select a function -- </option>
                  {this.props.preprocessFunctions.map((el2, index2) =>
                    el2.uri === this.state.preprocess[index].function.uri ?
                    <option key={index2} value={el2.uri} selected>{el2.uri}</option> : <option key={index2} value={el2.uri} >{el2.uri}</option>
                  )}
              </Form.Control>
              </Form.Group>

            {el.parameters && el.parameters.filter(el2 => el2 !== "input").map((el2,index2) =>
            <Form.Group key={index2}>
              <Form.Label>{el2.name}</Form.Label>
              <Form.Control onChange={(v) => this.functionParameterChanged(index, el2, v)}
                            defaultValue='' value = {el2.value}
                            required>
              </Form.Control>
            </Form.Group>)}
            </Col>
            <Col md="auto">
            <Button type="button" className="deleteeditbutton" aria-label="New" onClick={(event) => this.deletePreprocess(index)}><span className="fa fa-times"></span></Button>
            </Col>

            </Row>
          )}
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="primary">
              OK
            </Button>
            <Button variant="secondary" onClick={this.props.onClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
  )}
}

export default AnnotatorModal;
