import { API_BASE_URL } from '../../../constants/index.js';
import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { convert, adjustSchemaMapping, adjustContentMapping } from '../../../utils/D2RMLUtils';

export class NewMappingUploadModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      invalidFile: false,

      json: null,
      parameters: (props.mapping && props.mapping.parameters) ? props.mapping.parameters : [],
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    event.preventDefault();

    if (!this.state.invalidFile) {
      this.props.onOK(this.props.mapping ? this.props.mapping.id : null, this.name.value, this.state.json, this.state.parameters);
    }
  }

  addParameter() {
    var parameters = this.state.parameters.slice();
    parameters.push('')
    this.setState({parameters})
  }

  parameterChange(event, index) {
    var parameters = this.state.parameters.slice(0,index).concat([event.target.value]).concat(this.state.parameters.slice(index+1))
    this.setState({parameters})
  }


  fileChange(event) {
      var files = event.target.files; // FileList object
      var _this = this;
      var reader = new FileReader();
      reader.onload = function(event) {
        var ttl = event.target.result;

        console.log("BEFORE");
        console.log(ttl);

        fetch(API_BASE_URL + '/d2rml/ttl2jsonldx', {
          method: 'post',
          body: ttl
        }).then(response =>  {
          if (!response.ok) {
            _this.setState( { invalidFile: true } );
          } else {
            response.json()
              .then(json => {
                 json = convert(json);

                 console.log(json);
                 
                 if (_this.props.type === 'HEADER') {
                   json = adjustSchemaMapping(json, _this.props.uuid);
                 } else  if (_this.props.type === 'CONTENT') {
                    json = adjustContentMapping(json, _this.props.uuid);
                  }
                 console.log(JSON.stringify(json));

                 _this.setState({ json: JSON.stringify(json) });
                 _this.setState({ invalidFile: false });
               })
            }
          })
      }

    reader.readAsText(files[0]);
  }

  render() {
    return (
      <Modal show={this.props.show} onHide={this.props.onClose} animation={false} backdrop="static">
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header>
            <Modal.Title>New {this.props.type === 'CONTENT'? 'Content Mapping' : (this.props.type === 'METADATA' ? 'Metadata Mapping' : 'Prefix File')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control placeholder="Name" ref={node => (this.name = node)}
                   onChange={() => this.setState({ error: false })}
                   pattern="\S.*"
                   defaultValue={this.props.mapping ? this.props.mapping.name : ""}
                   required/>
            </Form.Group>
            <Form.Control.Feedback type="invalid">
              Please provide a valid name.
            </Form.Control.Feedback>
            <Form.Group>
              <Form.Label>File</Form.Label>
              {(this.props.mapping === null || !this.props.mapping.d2RML || this.props.mapping.d2RML === null) &&
              <Form.Control type="file"
                            onChange={(event) => this.fileChange(event)}
                            isInvalid={this.state.invalidFile} required/>}
              {(this.props.mapping !== null && this.props.mapping.d2RML !== null) &&
              <Form.Control type="file"
                            onChange={(event) => this.fileChange(event)}
                            isInvalid={this.state.invalidFile}/>}
              <Form.Control.Feedback type="invalid">
                Invalid file.
              </Form.Control.Feedback>
            </Form.Group>
            {this.props.type !== 'PREFIX' &&
              <Form.Group className="groupborder">
                <Row className="header">
                  <Col>Parameters</Col>
                  <Col md="auto">
                    <Button type="button" className="menubutton"  aria-label="Add"  onClick={()=> this.addParameter()}><span className='fa fa-plus'></span></Button>
                  </Col>
                </Row>
                {this.state.parameters.map((el,index) =>
                  <Row className="grouping" key={index}>
                    <Col>
                      <Form.Control value={el} onChange={(event) => this.parameterChange(event, index)}/>
                    </Col>
                  </Row>)}
              </Form.Group>}
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="primary">
              OK
            </Button>
            <Button variant="secondary" onClick={() => {this.setState({error: false, invalidFile: false, json: null, parameters: []}); this.props.onClose()}}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
  )}
}

export default NewMappingUploadModal;
