import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { filterByLanguage } from "../utils/functions.js";

import { Localizer } from '../config/localizer.js'

export class Header extends Component {

  render() {

    return (
      <Container className="appheader">
        <Row>
          <Col>
            <span className="mainTitle">{Localizer.semantic_network[this.props.language]}{this.props.database && ": "}</span><span className="mainTitle databaseTitle">{this.props.database.label}</span>
            {this.props.root &&
              <span className="mainTitle databaseSubTitle"> > {filterByLanguage(this.props.root,'http://www.w3.org/2000/01/rdf-schema#label', this.props.language)}</span>
            }
           </Col>
           <Col md="auto" >
{/*             <ReactCountryFlag countryCode="GB" svg onClick={()=>this.props.actions('change-language', {language: 'en'})}/><span>&nbsp;</span>
             <ReactCountryFlag countryCode="GR" svg onClick={()=>this.props.actions('change-language', {language: 'el'})}/> */}
             <Row>
                <Col onClick={()=>this.props.actions('change-language', {language: 'en'})}><span className="language">EN</span></Col>
                <Col onClick={()=>this.props.actions('change-language', {language: 'el'})}><span className="language">EL</span></Col>
              </Row>
           </Col>
          <Col className="mybutton" md="auto">
          <Row>
            <Col>
              <Button type="button" className="close" aria-label="Sign In" onClick={() => this.props.actions('sign-button-action')}><span className={this.props.user ? 'fa fa-sign-out' : 'fa fa-sign-in'}/></Button>
            </Col>
          </Row>
          {this.props.user &&
            <Row>
              <Col><span className="username">{this.props.user.username}</span></Col>
            </Row>}
          </Col>
        </Row>
      </Container>
  )}
}


export default Header
