import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import Collapse from "react-bootstrap/Collapse";

import { Localizer } from '../../config/localizer.js'
import { filterByLanguage } from "../../utils/functions.js";

import AnnotatorModal from "./modals/AnnotatorModal.js";
import VocabulizerModal from "./modals/VocabulizerModal.js";
import ExecutableState from "./ExecutableState.js";
import PublishableState from "./PublishableState.js";
import IndexableState from "./IndexableState.js";


export class SchemaProperty extends Component {
  constructor(props) {
    super(props);

    this.state = {
      annotationModalOpen: false,
      vocabularizerModalOpen: false,
      annotatorsOpen: false,
      annotationsOpen: false,

      // annotators: []
    }
  }


  toggleAnnotatorShow() {
    this.setState({ annotatorsOpen: !this.state.annotatorsOpen});
  }

  toggleAnnotationsShow() {
    this.setState({ annotationsOpen: !this.state.annotationsOpen});
  }

  adjustProperty(params) {
    if (params.onProperty) {
      return {...params, onProperty: params.onProperty.concat(this.props.value.info['@id']) }
    } else {
      return params;
    }
  }

  render() {
    // console.log(this.props);
    const annotators = this.props.annotators.filter(el => el.onProperty[0] === this.props.value.info['@id']);
    // const annotationEditGroups = this.props.annotationEditGroups.filter(el => el.onProperty[0] === this.props.value['@id']);

    // const annotationEditGroups = [];
    const annotationEditGroups = this.props.annotationEditGroups.filter(el => el.onProperty[0] === this.props.value.info['@id']);
    const asProperties = new Set();
    for (const i in annotators) {
      if (!asProperties.has(annotators[i].asProperty)) {
        asProperties.add(annotators[i].asProperty)
        // annotationEditGroups.push(annotators[i].editGroup);
      }
    }

    var vocabularizer = this.props.vocabularizers.filter(el => el.onProperty[0] === this.props.value.info['@id']);
    if (vocabularizer.length > 0) {
      vocabularizer = vocabularizer[0]
    } else {
      vocabularizer = null
    }

    // var index = this.props.indexes.filter(el => el.onProperty[0] === this.props.value['@id']);
    // if (index.length > 0) {
    //   index = index[0]
    // } else {
    //   index = null
    // }

     // console.log(editGroups);
     // console.log(this.props.queryProperties);

    return (
      <Container className={"grouping groupborder" + (vocabularizer || annotators.length > 0 || this.props.value.children ? "": "short")}>
        <Row  className={"header" + (vocabularizer || annotators.length > 0 || this.props.value.children ? "": "short")}>
          <Col>
            <Container>
              <Row>
                <span>{this.props.value.info['@id']}</span>
              </Row>
              {this.props.value.info['http://www.w3.org/2000/01/rdf-schema#label'] &&
              <Row>
                <span className="thin label">{filterByLanguage(this.props.value.info,'http://www.w3.org/2000/01/rdf-schema#label', this.props.language)}</span>
              </Row>}
          </Container>
        </Col>
          <Col className="thin" md="auto">
            <span>{this.props.value.count}</span>
          </Col>
          <Col className="mybutton" md="auto">
            <DropdownButton size="sm" title={Localizer.actions[this.props.language]} className="actions">
              <Dropdown.Item  onClick={() => this.setState({ annotationModalOpen: true})}>Add Annotator</Dropdown.Item>
              {!vocabularizer &&
              <Dropdown.Item  onClick={() => this.setState({ vocabularizerModalOpen: true})}>Vocabularize</Dropdown.Item> }
{/*              {!index &&
              <Dropdown.Item  onClick={() => this.props.actions('index', { onProperty: [this.props.value['@id']] })}>Index</Dropdown.Item> } */}
{/*              <Dropdown.Item  onClick={() => this.props.actions('unindex', { onProperty: [this.props.value['@id']] })}>Unindex</Dropdown.Item> /*}
{/*              <Dropdown.Item  onClick={() => this.props.actions('unpublish-annotators', { uri: this.props.value['@id']})}>Unpublish Annotators</Dropdown.Item> */}
              {annotators.length > 0 && <Dropdown.Item  onClick={(event) => this.props.actions('publish-checked-annotators', { onProperty: [this.props.value.info['@id']] })}>Publish Selected Annotators</Dropdown.Item>}
              {annotators.length > 0 && <Dropdown.Item  onClick={(event) => this.props.actions('unpublish-checked-annotators', { onProperty: [this.props.value.info['@id']] })}>Unpublish Selected Annotators</Dropdown.Item>}
            </DropdownButton>

            {this.state.annotationModalOpen && <AnnotatorModal show={this.state.annotationModalOpen}
                            queryProperties={this.props.queryProperties}
                            dataAnnotators={this.props.dataAnnotators}
                            preprocessFunctions={this.props.preprocessFunctions}
                            vocabularies={this.props.vocabularies}
                            onOK={(property, annotator, thesaurus, params, preprocess, variant ) => {this.props.actions('create-annotator', { onProperty: [this.props.value.info['@id']], asProperty: property, annotator: annotator, thesaurus: thesaurus, parameters: params, preprocess: preprocess, variant: variant}); this.setState({ annotationModalOpen: false })}}
                            onClose={() => this.setState({ annotationModalOpen: false })}/>}

            {this.state.vocabularizerModalOpen && <VocabulizerModal show={this.state.vocabularizerModalOpen}
                            onOK={(name, separator) => {this.props.actions('create-vocabularizer', { onProperty: [this.props.value.info['@id']], name:name, separator: separator}); this.setState({ vocabularizerModalOpen: false })}}
                            onClose={() => this.setState({ vocabularizerModalOpen: false })}/>}


          </Col>
          <Col className="mybutton" md="auto">
            <Button type="button" className="menubutton" aria-label="View values" onClick={() => this.props.valueActions('show-values',[{type:"property", uri: this.props.value['@id']}], "ALL")}><span className="fa fa-eye"></span></Button>
          </Col>
          <Col className="mybutton" md="auto">
            <Button type="button" className="menubutton" aria-label="Download values" onClick={() => this.props.valueActions('download-values', [{type:"property", uri: this.props.value['@id']}], "ALL")}><span className="fa fa-download"></span></Button>
          </Col>
        </Row>

{/*        <Row>
          <Col>
            {Localizer.count[this.props.language]}: <span>{this.props.value.count}</span>
          </Col>
        </Row> */}
{/*        {index &&
        <div>
          <Row className="tableheader">
          </Row>
          <Row className="tableheader">
            <Col>
              <span>Index</span>
            </Col>
            <Col md="4" className="tablecenter">
                <Row>
                  <Col>
                    <IndexableState value={index}/>
                  </Col>
                </Row>
            </Col>
            <Col className="mybutton" md="auto">
                <Button type="button" className="menubutton" aria-label="Unindex" onClick={() => this.props.actions('unindex', { onProperty: [this.props.value['@id']] })}><span className="fa fa-trash"></span></Button>
            </Col>
          </Row>
        </div>} */}
        {vocabularizer &&
        <div>
          <Row className="tableheader">
          </Row>
          <Row className="tableheader">
            <Col>
              <Row>
                <Col>
                 <span>Vocabularizer</span>
                 </Col>
              </Row>
              <Row>
                <Col>
                 <span><b>{vocabularizer.name}</b></span>
                 </Col>
              </Row>
            </Col>
            <Col md="4" className="tablecenter">
              <div>
                <Row>
                  <Col>
                    <ExecutableState value={vocabularizer}/>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <PublishableState value={vocabularizer}/>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <IndexableState value={vocabularizer}/>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col className="mybutton" md="auto">
                <Button type="button" className="menubutton" aria-label="Execute" onClick={() => this.props.actions('execute-vocabularizer', {id: vocabularizer.id})}><span className="fa fa-play-circle"></span></Button>
            </Col>
            <Col className="mybutton" md="auto">
                <Button type="button" className="menubutton" aria-label="View Last Execution" onClick={() => this.props.actions('view-vocabularizer-execution', {id: vocabularizer.id})}><span className="fa fa-eye"></span></Button>
            </Col>
            <Col className="mybutton" md="auto">
                <Button type="button" className="menubutton" aria-label="Delete" onClick={() => this.props.actions('delete-vocabularizer', {id: vocabularizer.id})}><span className="fa fa-trash"></span></Button>
            </Col>
            <Col className="mybutton" md="auto">
                <Button type="button" className="menubutton" aria-label="Publish" onClick={() => this.props.actions('publish-vocabularizer', {id: vocabularizer.id})}><span className="punp">P</span></Button>
            </Col>
            <Col className="mybutton" md="auto">
                <Button type="button" className="menubutton" aria-label="Unpublish" onClick={() => this.props.actions('unpublish-vocabularizer', {id: vocabularizer.id})}><span className="punp">U</span></Button>
            </Col>
            <Col className="mybutton" md="auto">
                <Button type="button" className="menubutton" aria-label="Index" onClick={() => this.props.actions('index-vocabularizer', {id: vocabularizer.id})}><span className="punp">X</span></Button>
            </Col>
            <Col className="mybutton" md="auto">
                <Button type="button" className="menubutton" aria-label="Unindex" onClick={() => this.props.actions('unindex-vocabularizer', {id: vocabularizer.id})}><span className="punp">U</span></Button>
            </Col>

          </Row>
        </div>}
        {vocabularizer  &&
          <Row>
            <Col>
            Clean up
            </Col>
            <Col md="4" className="tablecenter">
{/*              <div>
                <Row>
                  <Col>
                    <ExecutableState value={el.editGroup}/>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <PublishableState value={el.editGroup}/>
                  </Col>
                </Row>
              </div>*/}
            </Col>
            <Col className="mybutton" md="auto">
                <Button type="button" className="menubutton" aria-label="Execute" onClick={() => this.props.actions('execute-cleanup-vocabularizer', {id: vocabularizer.id})}><span className="fa fa-edit"></span></Button>
            </Col>
{/*            <Col className="mybutton" md="auto">
                <Button type="button" className="menubutton" aria-label="Execute" onClick={() => this.props.actions('execute-annotation-edits', {id: el.editGroup.id})}><span className="fa fa-play-circle"></span></Button>
            </Col> */}
            <Col className="mybutton" md="auto">
                <Button type="button" className="menubutton" aria-label="View" onClick={() => this.props.actions('view-cleanup-vocabularizer', {})}><span className="fa fa-eye"></span></Button>
            </Col>
{/*            <Col className="mybutton" md="auto">
                <Button type="button" className="menubutton" aria-label="Publish" onClick={() => this.props.actions('publish-annotation-edits', {id: el.editGroup.id})}><span className="punp">P</span></Button>
            </Col>
            <Col className="mybutton" md="auto">
                <Button type="button" className="menubutton" aria-label="Unpublish" onClick={() => this.props.actions('unpublish-annotation-edits', {id: el.editGroup.id})}><span className="punp">U</span></Button>
            </Col>*/}
          </Row>}
        {vocabularizer &&
          <Row className="tableheader">
          </Row>}
        {annotators.length > 0 &&
          <div>
            <Row className="tableheader">
            </Row>
            <Row className="tableheader annotators">
              <Col>
                <span>{Localizer.annotators[this.props.language]}</span>
              </Col>
              <Col className="mybutton" md="auto">
                <Button type="button" className="menubutton"  aria-label="Toggle"  onClick={() => this.toggleAnnotatorShow()}><span className={this.state.annotatorsOpen ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'}></span></Button>
              </Col>
            </Row>
            <Collapse in={this.state.annotatorsOpen}>
              <div>
                {annotators.map((el, index) =>
                <Row key={index} className="grouping bottomrow">
                  <Col md="auto">
                    <Form.Check value={el.selected}  onClick={() => this.props.actions('check-annotator', {id: el.id})}/>
                  </Col>
                  <Col>
                    <Row>
                      <Col><span className="crimson-std">{this.props.dataAnnotators.filter((ann) => ann.identifier === el.annotator)[0].title}</span>{!el.variant ? " as":""}</Col>
                    </Row>
                    {el.variant &&
                      <Row>
                        <Col>[<span className="crimson-std">{el.variant}</span>] as</Col>
                      </Row>
                    }
                    <Row>
                      <Col><span className="blueviolet-std">{el.asProperty}</span></Col>
                    </Row>
                    {el.parameters && el.parameters.length > 0 &&
                      <div>
                        <Row className="tableheader">
                        </Row>
                        <Row className="tableheader">
                          <Col>{Localizer.parameters[this.props.language]}</Col>
                        </Row>
                      </div>
                    }
                    {el.thesaurus &&
                      <Row>
                        <Col><span className="parameter">thesaurus: </span><span className="parametervalue">{this.props.vocabularies.find(v => v['http://purl.org/dc/elements/1.1/identifier'][0]['@id'] === el.thesaurus)? filterByLanguage(this.props.vocabularies.find(v => v['http://purl.org/dc/elements/1.1/identifier'][0]['@id'] === el.thesaurus),'http://www.w3.org/2000/01/rdf-schema#label',this.props.language) : el.thesaurus}</span></Col>
                      </Row>
                    }
                    {el.parameters && el.parameters.map((el2,index2) =>
                      <Row key={index2}>
                        <Col><span className="parameter">{el2.name}: </span><span className="parametervalue">{el2.value}</span></Col>
                      </Row>
                    )}
                    {el.preprocess &&
                      <div>
                        <Row className="tableheader">
                        </Row>
                        <Row className="tableheader">
                          <Col>Preprocess</Col>
                        </Row>
                      </div>}
                    {el.preprocess && el.preprocess.map((el1, index1) =>
                      <Row>
                        <Col md="auto">{index1 + 1}</Col>
                        <Col>
                        <Row key={index1}>
                          <Col><span className="parameter">function: </span><span className="parametervalue">{el1.function}</span></Col>
                        </Row>
                        {el1.parameters.map((el2,index2) =>
                          <Row key={index2}>
                            <Col><span className="parameter">{el2.name}: </span><span className="parametervalue">{el2.value}</span></Col>
                          </Row>
                        )}
                        </Col>
                      </Row>)}
                  </Col>
                  <Col md="4" className="tablecenter">
                    <div>
                      <Row>
                        <Col>
                          <ExecutableState value={el}/>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <PublishableState value={el}/>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col className="mybutton" md="auto">
                      <Button type="button" className="menubutton" aria-label="Execute" onClick={() => this.props.actions('execute-annotator', {id: el.id})}><span className="fa fa-play-circle"></span></Button>
                  </Col>
                  <Col className="mybutton" md="auto">
                      <Button type="button" disabled={el.executeState !== 'EXECUTED'} className="menubutton" aria-label="View Last Execution" onClick={() => this.props.actions('view-annotator-execution', {id: el.id})}><span className="fa fa-eye"></span></Button>
                  </Col>
                  <Col className="mybutton" md="auto">
                      <Button type="button" disabled={el.executeState !== 'EXECUTED'} className="menubutton" aria-label="Download" onClick={() => this.props.actions('download-annotator-execution', {id: el.id})}><span className="fa fa-download"></span></Button>
                  </Col>
                  <Col className="mybutton" md="auto">
                      <Button type="button" disabled={el.publishState !== "UNPUBLISHED"} className="menubutton" aria-label="Delete" onClick={() => this.props.actions('delete-annotator', {id: el.id})}><span className="fa fa-trash"></span></Button>
                  </Col>
                </Row>)}
              </div>
            </Collapse>
            <Row className="tableheader">
              </Row>
              <Row className="tableheader annotators">
                <Col>
                  <span>Published Annotations</span>
                </Col>
                <Col className="mybutton" md="auto">
                  <Button type="button" className="menubutton"  aria-label="Toggle"  onClick={() => this.toggleAnnotationsShow()}><span className={this.state.annotationsOpen ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'}></span></Button>
                </Col>
              </Row>
              <Collapse in={this.state.annotationsOpen}>
                <div>
                {annotationEditGroups.map((el, index) =>
                  <Row className="grouping bottomrow">
                    <Col>
                    {el.asProperty}
                    </Col>
                    <Col md="4" className="tablecenter">
                      <div>
                        <Row>
                          <Col>
                            <ExecutableState value={el}/>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <PublishableState value={el}/>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col className="mybutton" md="auto">
                        <Button type="button" className="menubutton" aria-label="Prepare Validation" onClick={() => this.props.actions('create-annotation-validation', {id: el.id })}><span className="fa fa-filter"></span></Button>
                    </Col>
                    <Col className="mybutton" md="auto">
                        <Button type="button" className="menubutton" aria-label="Edit" onClick={() => this.props.actions('edit-annotations', {id: el.id, page:1, mode:"ALL", annotators: annotators})}><span className="fa fa-edit"></span></Button>
                    </Col>
                    <Col className="mybutton" md="auto">
                        <Button type="button" className="menubutton" aria-label="Execute" onClick={() => this.props.actions('execute-annotation-edits', {id: el.id})}><span className="fa fa-play-circle"></span></Button>
                    </Col>
                    <Col className="mybutton" md="auto">
                        <Button type="button" className="menubutton" aria-label="View" onClick={() => this.props.actions('view-annotation-edits', {id: el.id})}><span className="fa fa-eye"></span></Button>
                    </Col>
                    <Col className="mybutton" md="auto">
                        <Button type="button" className="menubutton" aria-label="Publish" onClick={() => this.props.actions('publish-annotation-edits', {id: el.id})}><span className="punp">P</span></Button>
                    </Col>
                    <Col className="mybutton" md="auto">
                        <Button type="button" className="menubutton" aria-label="Unpublish" onClick={() => this.props.actions('unpublish-annotation-edits', {id: el.id})}><span className="punp">U</span></Button>
                    </Col>
                    <Col className="mybutton" md="auto">
                        <Button type="button" className="menubutton" aria-label="Download" onClick={() => this.props.actions('download-annotation-values', {id: el.id})}><span className="fa fa-download"></span></Button>
                    </Col>
                  </Row>)}
              </div>
            </Collapse>
          </div>}
          {this.props.value.children &&
            <Row>
               {this.props.value.children.map((el,index) =>
                <SchemaProperty key={index} value={el}
                                annotators={this.props.annotators}
                                annotationEditGroups={this.props.annotationEditGroups}
                                vocabularizers={this.props.vocabularizers}
                                indexes={this.state.indexes}
                                queryProperties={this.props.queryProperties}
                                dataAnnotators={this.props.dataAnnotators}
                                vocabularies={this.props.vocabularies}
                                preprocessFunctions={this.props.preprocessFunctions}
                                language={this.props.language}
                                actions={(action, params) => this.props.actions(action, this.adjustProperty(params))}
                                valueActions={(action, path, mode) => this.props.valueActions(action, [{type: "property", uri: this.props.value.info['@id']}].concat(path), mode)}/>
              )}
            </Row>}
      </Container>
    );
  }
}


export default SchemaProperty;
