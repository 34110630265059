import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { labelResource} from "../../../utils/APIUtils.js"
import { Localizer } from '../../../config/localizer.js'
import { filterByLanguage } from "../../../utils/functions.js";
import BarLoader from "react-spinners/BarLoader";

export class AnnotationValuesModal extends Component {
  constructor(props) {
    super(props);

    this.state = this.createState(props);

    this.resolveLabels();
  }

  resolveLabels() {
    var labelPromises = [];
    var _this = this;

    for (const i in _this.state) {
      if (i === 'keys') {
        continue;
      }
      for (const j in _this.state[i].details) {
         const current = _this.state[i].details[j];
         labelPromises.push(
                       new Promise(function(resolve, reject) {
                         labelResource(current.value)
                         .then((json) =>  {
                           _this.setState({ [i] : {..._this.state[i], details: _this.state[i].details.map((d, index) => (j != index) ? d : {...d, label: json})}}); // it should be != do not change
                           resolve();
                           })
                       }))
          if (current.value2 !== null) {
              labelPromises.push(
                                     new Promise(function(resolve, reject) {
                                       labelResource(current.value2)
                                       .then((json) =>  {
                                         _this.setState({ [i] : {..._this.state[i], details: _this.state[i].details.map((d, index) => (j != index) ? d : {...d, label2: json})}}); // it should be != do not change
                                         resolve();
                                         })
                                     }))
                                   }
       }
     }
  }

  componentWillReceiveProps(props) {
    var update = false;
    if (this.props.page !== props.page || this.props.mode !== props.mode) {
      update = true;
    }
    if (this.props.state === null ||
        (this.props.state !== null && (this.props.state.loaded !== props.state.loaded || this.props.state.loading !== props.state.loading || this.props.state.failed !== props.state.failed))) {
      update = true
    }

    if (update) {
      this.setState(this.createState(props), ()=>this.resolveLabels());
      // console.log("update");
    }
}

  hightlightArray(input, array) {
    var str = '';
    if (input.lexicalForm) {
      str = input.lexicalForm
    } else if (input.iri) {
      str = input.iri
    }

    var result = ""
    if (!array || array.length === 0) {
      result = str
    } else {

      var prev = 0;
      var index = 0;

      var last = -1;
      while (index < array.length) {
        if (!array[index].start || !array[index].end)  {
          index++;
          continue;
        }

        last = index;
        var s = array[index].start;
        var e = array[index].end;

        result += str.substr(prev,s - prev);

        for (var i = index + 1; i < array.length;) {
          var nextElement = array[i];

          if (nextElement.start && nextElement.end && e > nextElement.start) {
            if (nextElement.end >= e) {
              e = nextElement.end;
            }
            index++;
            i++;
          } else {
            break;
          }
        }

        result += '<span class="highlight">' +
        str.substr(s, e - s) +
        '</span>';

        index++;

        prev = e;
      }

      if (last >= 0) {
        result += str.substr(array[last].end);
      } else {
        result = str;
      }
    }

    return "<code><pre>" +  result + (input.language ? '<br/><span class="litlanguage">' + input.language  + '</span>': "") + "</pre></code>";

 }

  createState(props) {
      var state = {};
      var keys = [];

      for (const i in props.value) {
        keys.push(i);

        var details = [];
        for (const j in props.value[i].details) {
          var el = {...props.value[i].details[j]}
          if (el.state === 'ADD') {
            el = {...el, originalValue: el.value, originalValue2: el.value2}
          }
          if (el.state) {
            el = {...el, originalState: el.state}
          }

          details.push(el);
        }


        state['element' + i] = { value: props.value[i].onValue, details,  deleted: [], accepted: [] };
      }

      state.keys = keys;

      return state;
  }

  annotationStyle(state) {
    if (state === "ACCEPT") {
      return "td-accepted";
    }
    else if (state === "DELETE") {
      return "td-deleted";
    }
    else {
      return "td-normal";
    }
  }

  newAnnotation(event, index) {
    var obj = this.state['element' + index];
    obj = { ...obj, details : obj.details.concat({value:'', state:'ADD'})}

    this.setState({ ['element' + index]: obj })

    // console.log(obj)
  }

  editAnnotation(event, index, order) {
    var obj = this.state['element' + index]
    obj = { ...obj, details : obj.details.map((el,i) => {
       return i !== order ? el
                          : { ...el, value: event.target.value} })
       }

    this.setState({  ['element' + index]: obj  })

    // console.log(obj)
  }

  deleteAnnotation(index, order) {
    var obj = this.state['element' + index]

    var ann = obj.details[order];
    if (ann.state === 'ADD') {
      obj = { ...obj, details : obj.details.filter((el,i) => i !== order ), deleted: obj.deleted.concat(obj.details[order])}
    } else {
      obj = { ...obj, details : obj.details.map((el,i) => {
         return i !== order ? el
                            : el.state === 'DELETE' ? {...el, state: 'UNDELETE'} : {...el, state: 'DELETE'} })
         }
    }

    this.setState({  ['element' + index]: obj  })

    // console.log(obj)
  }

  acceptAnnotation(index, order) {
    var obj = this.state['element' + index]

    var ann = obj.details[order];
    if (ann.state === 'ADD') {
      obj = { ...obj, details : obj.details.filter((el,i) => i !== order ), accepted: obj.accepted.concat(obj.details[order])}
    } else {
      obj = { ...obj, details : obj.details.map((el,i) => {
         return i !== order ? el
                            : el.state === 'ACCEPT' ? {...el, state: 'UNACCEPT'} : {...el, state: 'ACCEPT'} })
         }
    }

    // console.log(obj);
    this.setState({  ['element' + index]: obj  }, )
  }

  prepareCommit() {
    // console.log(this.state);

    var edits = [];
    for (const i in this.state.keys) {
      var obj = this.state['element' + i];
      for (const j in obj.details) {
        if (obj.details[j].state === 'ADD') {
          if (obj.details[j].originalState === 'ADD' && obj.details[j].value !== obj.details[j].originalValue) {
            edits.push( {propertyValue: obj.value, id: obj.details[j].id, annotationValue: obj.details[j].value, editType:'ADD'} ) // change annotationEdit value
          } else if (!obj.details[j].originalState && obj.details[j].value.length > 0) {
            edits.push( {propertyValue: obj.value, annotationValue: obj.details[j].value, editType:'ADD'} ) // add annotationEdit
          }
        } else if (obj.details[j].state === 'UNDELETE') {
          if (obj.details[j].originalState === 'ACCEPT' || obj.details[j].originalState === 'DELETE') {
            edits.push( {propertyValue: obj.value, id: obj.details[j].id}) //delete annotationEdit
          }
        } else if (obj.details[j].state === 'UNACCEPT') {
          if (obj.details[j].originalState === 'ACCEPT' || obj.details[j].originalState === 'DELETE') {
            edits.push( {propertyValue: obj.value, id: obj.details[j].id}) //delete annotationEdit
          }
        } else if (obj.details[j].state === 'DELETE') {
          if (obj.details[j].originalState === 'ACCEPT') {
            edits.push( {propertyValue: obj.value, id: obj.details[j].id})
            edits.push( {propertyValue: obj.value, annotationValue: obj.details[j].value, editType:'DELETE'})
          } else if (!obj.details[j].originalState) {
            edits.push( {propertyValue: obj.value, annotationValue: obj.details[j].value, editType:'DELETE'})
          }
        } else if (obj.details[j].state === 'ACCEPT') {
          if (obj.details[j].originalState === 'DELETE') {
            edits.push( {propertyValue: obj.value, id: obj.details[j].id})
            edits.push( {propertyValue: obj.value, annotationValue: obj.details[j].value, editType:'ACCEPT'})
          } else if (!obj.details[j].originalState) {
            edits.push( {propertyValue: obj.value, annotationValue: obj.details[j].value, editType:'ACCEPT'})
          }
        }
    }

    for (const j in obj.deleted) {
      edits.push( {propertyValue: obj.value, id: obj.deleted[j].id})      //delete added annotationEdit
    }
  }

  return edits;
}

  render() {
    return (
      <Modal size="xl" show={this.props.show} onHide={() => this.props.onClose()} animation={false} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Annotations</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {this.props.state.loading &&
          <Col className="loader">
            <BarLoader
              css='spinner'
              height={6}
              width={200}
              color='crimson'
              loading={true}/>
          </Col>
        }
        {this.props.state.failed &&
          <Col>
            <span className="error">Loading values failed.</span>
          </Col>
        }
        {this.props.state.loaded &&
          <Row className="bottomrow">
            <Col md="auto">
            Page: {this.props.page}
            </Col>
            <Col md="auto">
            <ButtonGroup>
            {this.props.page > 1 &&
              <Button type="button" className="nextbutton" aria-label="Previous" onClick={() => this.props.actions('edit-annotations', {id:this.props.id, annotators:this.props.annotators, onProperty:this.props.onProperty, asProperty:this.props.asProperty, page:this.props.page - 1, mode:this.props.mode})}><span className="fa fa-chevron-left"></span></Button>}
             <Button type="button" className="nextbutton" aria-label="Next" onClick={() => this.props.actions('edit-annotations', {id:this.props.id, annotators:this.props.annotators, onProperty:this.props.onProperty, asProperty:this.props.asProperty, page:this.props.page + 1, mode:this.props.mode})}><span className="fa fa-chevron-right"></span></Button>
             </ButtonGroup>
            </Col>
            <Col></Col>
            {/*
            <Col md="auto">
            <Form.Control as="select" ref={node => (this.thesaurus = node)}
                          onChange={() => this.thesaurusChanged()}
                          defaultValue=''
                          required>
                <option value='*'> * </option>
                {this.props.annotators.map((el, index) =>
                   <option key={index} value={el.annotator}>{this.props.dataAnnotators.filter((ann) => ann.identifier === el.annotator)[0].title}</option>)}
            </Form.Control>
            </Col>*/}
            <Col md="auto">
              <ButtonGroup className="annotationFilters">
                <Button variant={this.props.mode==='ALL' ? "primary" : "secondary"} onClick={() => this.props.actions('edit-annotations', {id:this.props.id, annotators:this.props.annotators, onProperty:this.props.onProperty, asProperty:this.props.asProperty, page:1, mode:"ALL"})}>All</Button>
                <Button variant={this.props.mode==='ANNOTATED_ONLY' ? "primary" : "secondary"} onClick={() => this.props.actions('edit-annotations', {id:this.props.id, annotators:this.props.annotators, onProperty:this.props.onProperty, asProperty:this.props.asProperty, page:1, mode:"ANNOTATED_ONLY"})}>Annotated</Button>
                <Button variant={this.props.mode==='UNANNOTATED_ONLY' ? "primary" : "secondary"} onClick={() => this.props.actions('edit-annotations', {id:this.props.id, annotators:this.props.annotators, onProperty:this.props.onProperty, asProperty:this.props.asProperty, page:1, mode:"UNANNOTATED_ONLY"})}>Non Annotated</Button>
                {/*
                <Button variant={this.props.mode==='VALIDATED_ONLY' ? "primary" : "secondary"} onClick={() => this.props.actions('edit-annotations', {id:this.props.id, annotators:this.props.annotators, onProperty:this.props.onProperty, asProperty:this.props.asProperty, page:1, mode:"VALIDATED_ONLY"})}>Validated</Button>
                <Button variant={this.props.mode==='USER_VALIDATIONS' ? "primary" : "secondary"} onClick={() => this.props.actions('edit-annotations', {id:this.props.id, annotators:this.props.annotators, onProperty:this.props.onProperty, asProperty:this.props.asProperty, page:1, mode:"USER_VALIDATIONS"})}>My Validations</Button>
                */}
              </ButtonGroup>
            </Col>
        </Row>}
        {this.props.state.loaded &&
        <div className="scrollContainer">
        <div className="scroll">
        {this.state.keys.map(el =>
          <Row className="grouping bottomrow"  key={el}>
            <Col>
              <div dangerouslySetInnerHTML={{__html: this.hightlightArray(this.state['element' + el].value, this.state['element' + el].details)}}></div>
            </Col>
            <Col md="auto">
              {this.state['element' + el].details.length > 0 && this.state['element' + el].details[0].count}
            </Col>
            <Col>
              <Container>
              {this.state['element' + el].details.map((ann,index) =>
                <Row key={index} className="td-row align-items-center">
                  <Col md="auto">
                    <Button type="button" className={ann.state==='DELETE' ? "deleteeditbutton selected" : "deleteeditbutton"} aria-label="Delete" onClick={(event) => this.deleteAnnotation(el, index)}>
                      <span className={ann.state==='DELETE' ? "fa fa-times-circle" : "fa fa-times"}></span>
                    </Button>
                    <Button type="button" className={ann.state==='ACCEPT' ? "approveButton selected" : "approveButton"} aria-label="Approve" onClick={(event) => this.acceptAnnotation(el, index)}>
                      <span className={ann.state==='ACCEPT' ? "fa fa-check-circle" : "fa fa-check"}></span>
                    </Button>
                  </Col>
                  {ann.state !== 'ADD' ?
                    <Col>
                      <Row>
                      {ann.value2 ?
                        <Col>
                        <Row>
                      <a href={ann.value} rel='noreferrer noopener' target="_blank"><span className={ann.state === "DELETE" ? "td-deleted" : "td-normal"}>{ann.value}</span></a> -
                      </Row>
                      <Row>
                      <a href={ann.value2} rel='noreferrer noopener' target="_blank"><span className={ann.state === "DELETE" ? "td-deleted" : "td-normal"}>{ann.value2}</span></a>
                      </Row>
                      </Col>
                      :
                        <a href={ann.value} rel='noreferrer noopener' target="_blank"><span className={this.annotationStyle(ann.state)}>{ann.value}</span></a>
                      }
                      </Row>
                      {(ann.label && ann.label[0]) &&
                        <Row>
                        <span className="td-label">{filterByLanguage(ann.label[0],'http://www.w3.org/2000/01/rdf-schema#label', this.props.language)}</span>
                        {(ann.label2 && ann.label2[0]) &&
                         <span className="td-label"> - {filterByLanguage(ann.label2[0],'http://www.w3.org/2000/01/rdf-schema#label', this.props.language)}</span>}
                        </Row>}
                    </Col>
                  :
                    <Col>
                      <Row>
                        <Col>
                          <Form.Control value={ann.value} onChange={(event) => this.editAnnotation(event, el, index)}/>
                        </Col>
                        <Col md="auto">
                          <a href={ann.value} rel='noreferrer noopener' target="_blank"><span className="fa fa-link"/></a>
                        </Col>
                      </Row>
                    </Col>
                  }
                </Row>
              )}
              <Row className="addAnnotationRow">
                <Col md="auto">
                  <Button type="button" className="deleteaddbutton" aria-label="New" onClick={(event) => this.newAnnotation(event,el)}><span className="fa fa-plus"></span></Button>
                </Col>
              </Row>
              </Container>
            </Col>
          </Row>
        )}
        </div>
        </div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => this.props.actions('commit-annotation-edits', { id: this.props.id, edits: this.prepareCommit()})}>
            Commit Current Changes
          </Button>
          <Button variant="secondary" onClick={() =>this.props.onClose()}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  )}
}

export default AnnotationValuesModal;
