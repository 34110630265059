import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import {signin} from "../utils/APIUtils.js"

import { ACCESS_TOKEN } from '../constants/index.js';

export class SignInModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.show,
      error: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // handleLogInClose() {
  //   this.setState({ logInShow: false});
  // }

  handleSubmit(event) {
    event.preventDefault();

    signin({ username: this.username.value, password: this.password.value })
    .then(response => {
      localStorage.setItem(ACCESS_TOKEN, response.accessToken);
      this.props.onSignIn()
    })
    .catch(error => this.setState({ error:true}))

    // fetch('http://kimon.image.ece.ntua.gr:8080/users/signin', {
    //   method: 'post',
    //   body: JSON.stringify(),
    //   headers: {
    //     "Content-Type": "application/json"
    //   },
    // })
    // .then(checkStatus)
    // .then(response => this.props.onSignIn(this.username.value))
    // .catch(error => this.setState({ error:true}))
  }

  render() {
    // console.log(this.props.show);
    return (
      <Modal show={this.props.show} onHide={this.props.onClose} animation={false}>
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header>
            <Modal.Title>Sign In</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Username</Form.Label>
              <Form.Control placeholder="Username" ref={node => (this.username = node)}
                   onChange={() => this.setState({ error: false })}
                   isInvalid={!!this.state.error}/>
            </Form.Group>

            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" placeholder="Password" ref={node => (this.password = node)}
                      onChange={() => this.setState({ error: false })}
                      isInvalid={!!this.state.error}/>
              <Form.Control.Feedback type="invalid">
                  Invalid username or password.
              </Form.Control.Feedback>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="primary">
              OK
            </Button>
            <Button variant="secondary" onClick={this.props.onClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
  )}
}

export default SignInModal;
