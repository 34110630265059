import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import BarLoader from "react-spinners/BarLoader";
import { labelResource} from "../../../utils/APIUtils.js";
import { filterByLanguage } from "../../../utils/functions.js";
import { Localizer } from "../../../config/localizer.js";

export class ValidationModal extends Component {
  constructor(props) {
    super(props);
    this.state = this.createState(props);
    this.resolveLabels();

    this.handlePageSelection = this.handlePageSelection.bind(this);
  }

  handlePageSelection(event) {
    event.preventDefault();
    let page = this.selectedPage;
    if (typeof page.value !== "undefined") {
      if (page.value.length > 0) {
        let num = parseInt(page.value);
        if (page.value > 0 && page.value < 1000) {
          this.gotoPage(false, num, this.props.value.mode);
        }
      }
    }
  }

  gotoPage(goback, page, mode) {
    let history = this.props.pageHistory;
    let obj = {
      id: this.props.value.id,
      // annotators: this.props.annotators,
      // onProperty: this.props.onProperty,
      // asProperty: this.props.asProperty,
      page: page,
      mode: mode
    };

    if (mode !== this.props.value.mode) {
      obj.pageHistory = [];
    }
    else if (!goback) {
      history.unshift(this.props.value.currentPage.toString());
      obj.pageHistory = history;
    }
    else {
      history.splice(0,1);
      obj.pageHistory = history;
    }

    this.props.actions('validate-annotations',obj);
  }

  resolveLabels() {

    var labelPromises = [];
    var _this = this;

    var newElements = this.state.elements.slice();

    // for (const i in _this.state) {
    for (const i in _this.state.elements) {
      // console.log(i);
      // if (i === 'keys') {
      //   continue;
      // }
      // for (const j in _this.state[i].details) {
      for (const j in _this.state.elements[i].details) {
        // const current = _this.state[i].details[j];
        const current = _this.state.elements[i].details[j];
        labelPromises.push(
          new Promise(function(resolve, reject) {
            labelResource(current.value)
              .then(json =>  {
                // _this.setState({ [i] : {..._this.state[i], details: _this.state[i].details.map((d, index) => (j !== index) ? d : {...d, label: json})}}); // it should be != do not change
                // var obj = {..._this.state.elements[i], details: _this.state.elements[i].details.map((d, index) => (j !== index) ? d : {...d, label: json})};
                // _this.setState({ elements: _this.state.elements.slice(0, i).concat(obj).concat(_this.state.elements.slice(i + 1))});
                newElements[i].details[j] = { ...newElements[i].details[j], label:json};
                resolve();
              }).catch(() => resolve())
          })
        )
        if (current.value2 !== null) {
          labelPromises.push(
            new Promise(function(resolve, reject) {
              labelResource(current.value2)
                .then((json) =>  {
                  // _this.setState({ [i] : {..._this.state[i], details: _this.state[i].details.map((d, index) => (j !== index) ? d : {...d, label2: json})}}); // it should be != do not change
                  // var obj = {..._this.state.elements[i], details: _this.state.elements[i].details.map((d, index) => (j !== index) ? d : {...d, label2: json})};
                  // _this.setState({ elements: _this.state.elements.slice(0, i).concat(obj).concat(_this.state.elements.slice(i + 1))});
                  newElements[i].details[j] = { ...newElements[i].details[j], label:json};
                  resolve();
                }).catch(() => resolve())
            })
          )
        }
      }
    }

    Promise.all(labelPromises).then(() => this.setState({elements: newElements}));
  }

  hightlightArray(input, array) {

    var str = '';
    if (input.lexicalForm) {
      str = input.lexicalForm
    } else if (input.iri) {
      str = input.iri
    }
    var result = ""
    if (!array || array.length === 0) {
      result = str
    } else {
      var prev = 0;
      var index = 0;
      var last = -1;
      while (index < array.length) {
        if (array[index].start === undefined || array[index].end === undefined || array[index].start < 0 || array[index].end < 0)  {
          index++;
          continue;
        }
        last = index;
        var s = array[index].start;
        var e = array[index].end;
        result += str.substr(prev,s - prev);
        for (var i = index + 1; i < array.length;) {
          var nextElement = array[i];
          if (nextElement.start && nextElement.end && e > nextElement.start) {
            if (nextElement.end >= e) {
              e = nextElement.end;
            }
            index++;
            i++;
          } else {
            break;
          }
        }
        result += '<span class="highlight">' +
        str.substr(s, e - s) +
        '</span>';
        index++;
        prev = e;
      }
      if (last >= 0) {
        result += str.substr(array[last].end);
      }
      else {
        result = str;
      }
    }
    return "<code><pre class='mb-0'>" +  result + (input.language ? '<br/><span class="litlanguage">' + input.language  + '</span>': "") + "</pre></code>";
 }

  createState(props) {
      var state = {};
      // var keys = [];

      var elements = [];

      for (const i in props.value.data) {
        // keys.push(i);
        var details = [];
        for (const j in props.value.data[i].details) {
          var el = {...props.value.data[i].details[j]}
          if (el.state === 'ADD') {
            el = {...el, originalValue: el.value, originalValue2: el.value2}
          }
          if (el.state) {
            el = {...el, originalState: el.state}
          }
          el = {...el, othersRejected: el.othersRejected, othersAccepted: el.othersAccepted}

          details.push(el);
        }
        // state['element' + i] = { value: props.value[i].onValue, details,  deleted: [], accepted: [] };
        elements.push({ value: props.value.data[i].onValue, count: props.value.data[i].count, details,  deleted: [], accepted: [] })
      }
      // state.keys = keys;
      state.elements = elements;
      return state;
  }

  annotationStyle(state) {
    if (state === "ACCEPT") {
      return "td-accepted";
    } else if (state === "REJECT") {
      return "td-deleted";
    } else {
      return "td-normal";
    }
  }

  newAnnotation(event, index) {
    // var obj = this.state['element' + index];
    var obj = this.state.elements[index];
    var add = {value:'', state:'ADD', othersAccepted: 0, othersRejected: 0}
    obj = { ...obj, details : obj.details.concat(add)}
    // this.setState({ ['element' + index]: obj });
     this.setState({ elements: this.state.elements.slice(0, index).concat(obj).concat(this.state.elements.slice(index + 1))});
  }

  editAnnotation(event, index, order) {
    // var obj = this.state['element' + index]
    var obj = this.state.elements[index];
    obj = { ...obj, details : obj.details.map((el,i) => {
      return i !== order ? el : { ...el, value: event.target.value} })
    }
    // this.setState({  ['element' + index]: obj  });
    this.setState({ elements: this.state.elements.slice(0, index).concat(obj).concat(this.state.elements.slice(index + 1))});
  }

  deleteAnnotation(index, ann) {
    // var obj = this.state['element' + index]
    var obj = this.state.elements[index]
    // var ann = obj.details[order];
    if (ann.state === 'ADD') {
      // obj = { ...obj, details : obj.details.filter((el,i) => i !== order ), deleted: obj.deleted.concat(obj.details[order])}
        obj = { ...obj, details : obj.details.filter((el,i) => el.value !== ann.value ), deleted: obj.deleted.concat(obj.details.filter((el,i) => el.id && el.value === ann.value ))}
    } else {
      obj = { ...obj, details : obj.details.map((el,i) => {
         // return i !== order ? el : el.state === 'REJECT' ? {...el, state: 'UNREJECT'} : {...el, state: 'REJECT'} })
         return el.value !== ann.value ? el : el.state === 'REJECT' ? {...el, state: 'UNREJECT'} : {...el, state: 'REJECT'} })
      }
    }
    // this.setState({  ['element' + index]: obj  })
    this.setState({ elements: this.state.elements.slice(0, index).concat(obj).concat(this.state.elements.slice(index + 1))});
  }

  acceptAnnotation(index, ann) {
    // var obj = this.state['element' + index];
    var obj = this.state.elements[index];
    // var ann = obj.details[order];
    if (ann.state === 'ADD') {
      // obj = { ...obj, details : obj.details.filter((el,i) => i !== order ), accepted: obj.accepted.concat(obj.details[order])}
      obj = { ...obj, details : obj.details.filter((el,i) =>  el.value !== ann.value ), accepted: obj.accepted.concat(obj.details.filter((el,i) => el.value === ann.value ))}
    } else {
      obj = { ...obj, details : obj.details.map((el,i) => {
         // return i !== order ? el : el.state === 'ACCEPT' ? {...el, state: 'UNACCEPT'} : {...el, state: 'ACCEPT'} })
         return  el.value !== ann.value ? el : el.state === 'ACCEPT' ? {...el, state: 'UNACCEPT'} : {...el, state: 'ACCEPT'} })
     }
    }
    // this.setState({  ['element' + index]: obj  }, )
    this.setState({ elements: this.state.elements.slice(0, index).concat(obj).concat(this.state.elements.slice(index + 1))});
  }

  prepareCommit() {
    // console.log(this.state.elements)
    var edits = [];
    // for (const i in this.state.keys) {
    for (const i in this.state.elements) {
      // var obj = this.state['element' + i];
      var obj = this.state.elements[i];
      for (const j in obj.details) {
        if (obj.details[j].state === 'ADD') {
          if (obj.details[j].originalState === 'ADD' && obj.details[j].value !== obj.details[j].originalValue) {
            edits.push( {propertyValue: obj.value, id: obj.details[j].id, annotationValue: obj.details[j].value, editType:'ADD', start: -1, end: -1, count: obj.count} ) // change annotationEdit value
          } else if (!obj.details[j].originalState && obj.details[j].value.length > 0) {
            edits.push( {propertyValue: obj.value, id: obj.details[j].id, annotationValue: obj.details[j].value, editType:'ADD', start: -1, end: -1, count: obj.count} ) // add annotationEdit
          }
        } else if (obj.details[j].state === 'UNREJECT') {
          if (obj.details[j].originalState === 'ACCEPT' || obj.details[j].originalState === 'REJECT') {
            edits.push( {propertyValue: obj.value, id: obj.details[j].id, count: obj.count}) //delete annotationEdit
          }
        } else if (obj.details[j].state === 'UNACCEPT') {
          if (obj.details[j].originalState === 'ACCEPT' || obj.details[j].originalState === 'REJECT') {
            edits.push( {propertyValue: obj.value, id: obj.details[j].id, count: obj.count}) //delete annotationEdit
          }
        } else if (obj.details[j].state === 'REJECT') {
          if (obj.details[j].originalState === 'ACCEPT') {
            edits.push( {propertyValue: obj.value, id: obj.details[j].id, count: obj.count})
            edits.push( {propertyValue: obj.value, id: obj.details[j].id, annotationValue: obj.details[j].value, editType:'REJECT', start: obj.details[j].start, end: obj.details[j].end, count: obj.count})
          } else if (!obj.details[j].originalState) {
            edits.push( {propertyValue: obj.value, id: obj.details[j].id, annotationValue: obj.details[j].value, editType:'REJECT', start: obj.details[j].start, end: obj.details[j].end, count: obj.count})
          }
        } else if (obj.details[j].state === 'ACCEPT') {
          if (obj.details[j].originalState === 'REJECT') {
            edits.push( {propertyValue: obj.value, id: obj.details[j].id, count: obj.count})
            edits.push( {propertyValue: obj.value, id: obj.details[j].id, annotationValue: obj.details[j].value, editType:'ACCEPT', start: obj.details[j].start, end: obj.details[j].end, count: obj.count})
          } else if (!obj.details[j].originalState) {
            edits.push( {propertyValue: obj.value, id: obj.details[j].id, annotationValue: obj.details[j].value, editType:'ACCEPT', start: obj.details[j].start, end: obj.details[j].end, count: obj.count})
          }
        }
      }

      for (const j in obj.deleted) {
        edits.push( {propertyValue: obj.value, id: obj.deleted[j].id, count: obj.count})      //delete added annotationEdit
      }
    }

    return edits;
  }

  componentWillReceiveProps(props) {
    this.selectedPage.value = '';
    var update = false;
    if (this.props.value.currentPage !== props.value.currentPage || this.props.value.mode !== props.value.mode) {
      update = true;
    }
    if ( this.props.state === null ||
         (this.props.state !== null &&
           (this.props.state.loaded !== props.state.loaded || this.props.state.loading !== props.state.loading || this.props.state.failed !== props.state.failed) ) ) {
      update = true
    }
    if (update) {
      this.setState( this.createState(props), ()=> this.resolveLabels() );
    }
  }

  render() {

    //console.log(this.state);
    return (
      <Modal
        size="xl"
        show={this.props.show}
        onHide={() => this.props.onClose()}
        animation={false}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Validations</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {this.props.state.loading &&
            <Col className="loader">
              <BarLoader
                css='spinner'
                height={6}
                width={200}
                color='crimson'
                loading={true}/>
            </Col>
          }
          {this.props.state.failed &&
            <Col>
              <span className="error">Loading values failed.</span>
            </Col>
          }
          {this.props.state.loaded &&
            <Row className="bottomrow modalFilters">
              {this.props.value.currentPage > 1 &&
                <React.Fragment>
                  <Col md="auto pr-1">
                    <Button type="button" className="nextbutton" aria-label="Previous" onClick={() => this.gotoPage(true, this.props.pageHistory[0], this.props.value.mode)}>
                      <span className="fa fa-chevron-left"></span>
                    </Button>
                  </Col>
                  <Col md="auto pl-1 backButtonLabel">
                    Previous
                  </Col>
                </React.Fragment>
              }
              <Col></Col>
              <Col md="auto">
                <ButtonGroup className="annotationFilters">
                  {/*<Button variant={this.props.value.mode==='ALL' ? "primary" : "secondary"} onClick={() => this.gotoPage(false, 1, "ALL")}>All</Button>*/}
                  <Button variant={this.props.value.mode==='ANNOTATED_ONLY' ? "primary" : "secondary"} onClick={() => this.gotoPage(false, 1, "ANNOTATED_ONLY")}>Annotated</Button>
                  <Button variant={this.props.value.mode==='UNANNOTATED_ONLY' ? "primary" : "secondary"} onClick={() => this.gotoPage(false, 1, "UNANNOTATED_ONLY")}>Non Annotated</Button>
                  <Button variant={this.props.value.mode==='VALIDATED_ONLY' ? "primary" : "secondary"} onClick={() => this.gotoPage(false, 1, "VALIDATED_ONLY")}>Validated</Button>
                  <Button variant={this.props.value.mode==='USER_VALIDATIONS' ? "primary" : "secondary"} onClick={() => this.gotoPage(false, 1, "USER_VALIDATIONS")}>My Validations</Button>
                </ButtonGroup>
              </Col>
            </Row>
          }
          {this.props.state.loaded &&
            <div className="scrollContainer">
              <div className="scroll">
                {this.state.elements.map((el,elindex) =>
                  <Row className="grouping bottomrow"  key={elindex}>
                    <Col className="align-items-center">
                      <div dangerouslySetInnerHTML={{__html: this.hightlightArray(el.value, el.details)}}></div>
                    </Col>
                    <Col md="auto">
                      {el.count}
                    </Col>
                    <Col>
                      <Container>
                      {el.details.map((ann,annindex) =>
                        <Row key={annindex} className={"td-row align-items-center align-self-center " + (ann.othersRejected > ann.othersAccepted ? " reject-bias" : (ann.othersAccepted > ann.othersRejected ? " accept-bias" : "")) }>
                        {ann.state !== 'ADD' ?
                          <Col md={2}>
                            <Button type="button" className={ann.state==='REJECT' ? "deleteeditbutton selected" : "deleteeditbutton"} aria-label="Delete" onClick={(event) => this.deleteAnnotation(elindex, ann)}>
                              <span className={ann.state==='REJECT' ? "fa fa-times-circle" : "fa fa-times"}></span>
                            </Button>
                            <Button type="button" className={ann.state==='ACCEPT' ? "approveButton selected" : "approveButton"} aria-label="Approve" onClick={(event) => this.acceptAnnotation(elindex, ann)}>
                              <span className={ann.state==='ACCEPT' ? "fa fa-check-circle" : "fa fa-check"}></span>
                            </Button>
                          </Col> :
                          <React.Fragment>
                          {(ann.state === 'ADD' && ann.othersAccepted === 0 && ann.othersRejected === 0) ?
                            <Col md={2}>
                            <Button type="button" className={ann.state==='REJECT' ? "deleteeditbutton selected" : "deleteeditbutton"} aria-label="Delete" onClick={(event) => this.deleteAnnotation(elindex, ann)}>
                              <span className={ann.state==='REJECT' ? "fa fa-times-circle" : "fa fa-times"}></span>
                            </Button>
                            </Col> :
                          <Col md={2}></Col>}
                          </React.Fragment>}


                          {ann.state !== 'ADD' || ann.othersAccepted > 0 || ann.othersRejected > 0 ?
                            <Col>
                              <Row>
                              {ann.value2 ?
                                <Col>
                                  <Row>
                                    <a href={ann.value} rel='noreferrer noopener' target="_blank"><span className={ann.state === "REJECT" ? "td-deleted" : "td-normal"}>{ann.value}</span></a> -
                                  </Row>
                                  <Row>
                                    <a href={ann.value2} rel='noreferrer noopener' target="_blank"><span className={ann.state === "REJECT" ? "td-deleted" : "td-normal"}>{ann.value2}</span></a>
                                  </Row>
                                </Col>
                              :
                                <a href={ann.value} rel='noreferrer noopener' target="_blank"><span className={this.annotationStyle(ann.state)}>{ann.value}</span></a>
                              }
                              </Row>
                              {(ann.label && ann.label[0]) &&
                                <Row>
                                <span className="td-label">{filterByLanguage(ann.label[0],'http://www.w3.org/2000/01/rdf-schema#label', this.props.language)}</span>
                                {(ann.label2 && ann.label2[0]) &&
                                 <span className="td-label"> - {filterByLanguage(ann.label2[0],'http://www.w3.org/2000/01/rdf-schema#label', this.props.language)}</span>}
                                </Row>}
                            </Col>
                          :
                            <Col>
                              <Row>
                                <Col>
                                  <Form.Control value={ann.value} onChange={(event) => this.editAnnotation(event, elindex, annindex)}/>
                                </Col>
                                <Col md="auto align-self-center">
                                  <a href={ann.value} rel='noreferrer noopener' target="_blank"><span className="fa fa-link"/></a>
                                </Col>
                              </Row>
                            </Col>
                          }
                        </Row>
                      )}
                      <Row className="addAnnotationRow">
                        <Col md="auto">
                          <Button type="button" className="deleteaddbutton" aria-label="New" onClick={(event) => this.newAnnotation(event,elindex)}><span className="fa fa-plus"></span></Button>
                        </Col>
                      </Row>
                      </Container>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          }
        </Modal.Body>

        <Modal.Footer>
          <Row className="modalFooter">
            <Col md="auto">
              <Button variant="outline-danger" onClick={() =>this.props.onClose()}>
                Cancel
              </Button>
            </Col>
            <Col md="auto" className="modalPagination">
              <Button type="button" disabled={this.props.value.currentPage <= 1} variant={this.props.value.currentPage <= 1 ? "outline-light" : "light"} aria-label="Previous" className="nextbutton" onClick={() => this.gotoPage(false, this.props.value.currentPage-1, this.props.value.mode)}>
                <span className="fa fa-chevron-left"></span>
              </Button>
              <div className="align-self-center mx-2">
                Page
              </div>
              <Form id="page-selector" onSubmit={this.handlePageSelection}>
                <Form.Row>
                  <Col>
                    <Form.Control
                      placeholder={this.props.value.currentPage}
                      as="input"
                      maxLength={3}
                      className="pageSelector"
                      ref={node => (this.selectedPage = node)}
                    />
                  </Col>
                </Form.Row>
              </Form>
              <div className="align-self-center mx-2">
                of <strong>{this.props.value.totalPages}</strong>
              </div>
              <Button type="button" variant="light" aria-label="Next" className="nextbutton" onClick={() => this.gotoPage(false, this.props.value.currentPage+1, this.props.value.mode)}>
                <span className="fa fa-chevron-right"></span>
              </Button>
            </Col>
            <Col md="auto">
              <Button variant="primary" onClick={() => this.props.actions('commit-annotation-validation-page', { id: this.props.value.id, pageid: this.props.value.pagedAnnotationValidationPageId, page:this.props.value.currentPage, mode: this.props.value.mode, edits: this.prepareCommit()})}>
                Commit
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
  )}
}

export default ValidationModal;
