import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import BarLoader from "react-spinners/BarLoader";
import { Localizer } from '../../../config/localizer.js'

export class PropertyValuesModal extends Component {

  render() {
    // console.log(this.props.value);
    return (
      <Modal show={this.props.show} onHide={this.props.onClose} animation={false} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{Localizer.values[this.props.language]}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {this.props.state.loading &&
          <Col className="loader">
            <BarLoader
              css='spinner'
              height={6}
              width={200}
              color='crimson'
              loading={true}/>
          </Col>
        }
        {this.props.state.failed &&
          <Col>
            <span className="error">Loading values failed.</span>
          </Col>
        }
        {this.props.state.loaded &&
          <Row className="bottomrow">
            <Col></Col>
            <Col md="auto">
              <ButtonGroup>
                <Button variant="secondary" onClick={() => this.props.valueActions('show-values', this.props.path, "ALL")}>{Localizer.all_values[this.props.language]}</Button>
                <Button variant="secondary" onClick={() => this.props.valueActions('show-values', this.props.path, "LITERAL")}>{Localizer.literals[this.props.language]}</Button>
                <Button variant="secondary" onClick={() => this.props.valueActions('show-values', this.props.path, "IRI")}>{Localizer.iris[this.props.language]}</Button>
              </ButtonGroup>
            </Col>
        </Row>}
        {this.props.state.loaded &&
        <div className="scrollContainer">
        <div className="scroll">
        {this.props.value.map((el, index) =>
          <Row className="grouping" key={index}>
            <Col md="auto">
              {index +1}
            </Col>
            <Col>
              {el.literal ? <span>{el.value}</span> : <span><a target="_blank" rel='noopener noreferrer' href={el.value}>{el.value}</a></span>}
            </Col>
            <Col md="auto">
              {el.count}
            </Col>
          </Row>
        )}
        </div>
        </div>}
        </Modal.Body>
        <Modal.Footer>
          <Button primary="secondary" onClick={this.props.onClose}>
            {Localizer.close[this.props.language]}
          </Button>
        </Modal.Footer>
      </Modal>
  )}
}

export default PropertyValuesModal;
