export const Localizer =
{
  signInModal: {
    login: { en: "Log in", el: "Σύνδεση" },
    signup: { en: "Sign up", el: "Εγγραφή" },
    emailAddress: { en: "E-mail address", el: "Διεύθυνση e-mail" },
    password: { en: "Password", el: "Κωδικός" },
    repeatPassword: { en: "Repeat password", el: "Επανάληψη κωδικού" },
    fullname: { en: "Full name", el: "Ονοματεπώνυμο" },
    description: { en: "Description", el: "Περιγραφή" },
    invalidCredentials: { en: "Invalid e-mail or password", el: "Λάθος e-mail ή κωδικός χρήστη" },
    invalidPasswordConfirmation: { en: "Password confirmation doesn't match with password", el: "Ο κωδικός δεν ταιριάζει με την επαλήθευσή του" },
    existingEmailError: { en: "There is already a user with that e-mail", el: "Υπάρχει ήδη χρήστης με το συγκεκριμένο e-mail" }
  },
  semantic_network: { en: "Semantic Network", el: "Σημασιολογικό Δίκτυο" },
  search: { en: "Search", el: "Αναζήτηση" },
  new_search: { en: "New Search", el: "Νέα Αναζήτηση" },
  results: { en: "Results", el: "Αποτελέσματα" },
  user_space: { en: "User Space", el: "Χώρος Χρήστη" },
  data_collections: { en: "Data Collections", el: "Συλλογές Δεδομένων" },
  time_vocabularies: { en: "Temporal Knowledges", el: "Χρονικές Γνώσεις" },
  place_vocabularies: { en: "Spatial Knowledges", el: "Χωρικές Γνώσεις" },
  thesauri: { en: "General Knowledges", el: "Γενικές Γνώσεις" },
  list: { en: "List", el: "Κατάλογος" },
  map: { en: "Map", el: "Χάρτης" },
  check: { en: "Check", el: "Έλεγχος" },
  from: { en: "From", el: "Από" },
  until: { en: "Until", el: "Έως" },
  location: { en: "Location", el: "Τοποθεσία" },
  term: { en: "Term", el: "Όρος" },
  data_import: { en: "Collections Import", el: "Εισαγωγή Συλλογών" },
  vocabulary_import: { en: "Vocabulary Import", el: "Εισαγωγή Λεξιλογίων" },
  alignments: { en: "Alignments", el: "Αντιστοιχίσεις" },
  annotations: { en: "Annotations", el: "Επισημειώσεις" },
  annotations_import: { en: "Annotations Import", el: "Εισαγωγή Επισημειώσεων" },
  published_datasets: { en: "Published Collections", el: "Δημοσιευμένες Συλλογές" },
  vocabularies: { en: "Vocabularies", el: "Λεξιλόγια" },
  catalogs: { en: "Catalogs", el: "Κατάλογοι" },
  datasets: { en: "Datasets", el: "Σύνολα δεδομένων" },
  count:  { en: "Count", el: "Πλήθος" },
  actions:  { en: "Actions", el: "Ενέργειες" },
  values:  { en: "Values", el: "Τιμές" },
  close:  { en: "Close", el: "Κλείσιμο" },
  all_values:  { en: "All", el: "Όλες" },
  literals:  { en: "Literals", el: "Λεκτικά" },
  iris:  { en: "IRIs", el: "IRI" },
  annotators:  { en: "Annotators", el: "Επισημειωτές" },
  parameters:  { en: "Parameters", el: "Παράμετροι" },
  no_search_criteria:  { en: "No search criteria have been specified.", el: "Δεν έχουν καθοριστεί κριτήρια αναζήτησης." },
  search_failed:  { en: "Search failed.", el: "Η αναζήτηση απέτυχε." },
  no_results_found:  { en: "No results found.", el: "Δεν βρέθηκαν αποτελέσματα." },
  explore:  { en: "Explore", el: "Εξερεύνηση" },
  manage:  { en: "Manage", el: "Διαχείριση" },
}
