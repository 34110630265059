import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { filterByLanguage } from "../../../utils/functions.js";

export class NewMappingModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: this.props.show,
      error: false,
      class: null

    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  classChanged() {
    this.setState( { class : this.class.value })
  }

  handleSubmit(event) {
    event.preventDefault();
    this.props.onOK(this.props.dataset ? this.props.dataset.id : null, this.name.value, this.type ? this.type.value : undefined, this.property? this.property.value : undefined, this.class ? this.class.value : undefined,  this.ontology ? this.ontology.value : undefined);
  }

  render() {
    console.log(this.props);
    if (this.props.dataset) {
      var typeUri = '';
      if (this.props.types) {
        for (var i in this.props.types) {
           for (var j in this.props.dataset.typeUri) {
             if (this.props.types[i].uri === this.props.dataset.typeUri[j])  {
               typeUri = this.props.types[i].uri
             }
           }
         }
      }
    }

    return (
      <Modal show={this.props.show} onHide={this.props.onClose} animation={false} backdrop="static">
        <Form onSubmit={this.handleSubmit}>
          <Modal.Header>
            <Modal.Title>{!this.props.dataset ? 'Create' : 'Edit'} {this.props.type}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control ref={node => (this.name = node)}
                   onChange={() => this.setState({ error: false })}
                   pattern="\S.*"
                   defaultValue={this.props.dataset ? this.props.dataset.name : ""}
                   required/>
            </Form.Group>
            <Form.Control.Feedback type="invalid">
              Provide a valid name.
            </Form.Control.Feedback>
            {this.props.queryProperties &&
            <Form.Group>
              <Form.Label>View as</Form.Label>
              <Form.Control as="select" ref={node => (this.property = node)}
                            defaultValue=''
                            required>
                <option hidden disabled value=''> -- Select a property -- </option>
                {this.props.queryProperties.map((el, index) =>
                  <option key={index} value={el['@id']}>{el['@id']}</option>
                )}
              </Form.Control>
            </Form.Group>}
            {this.props.types &&
            <Form.Group>
              <Form.Label>Type</Form.Label>
              <Form.Control as="select" ref={node => (this.type = node)}
//                   onChange={() => this.typeChanged()}
                   defaultValue={typeUri} required>
                   <option hidden disabled value=''> -- Select type -- </option>
                   {this.props.types.map((el, index) =>
                      <option key={index} value={el.uri}>{el.label}</option>)}
              </Form.Control>
            </Form.Group>}
            {this.props.classes &&
            <Form.Group>
              <Form.Label>Class</Form.Label>
              <Form.Control as="select" ref={node => (this.class = node)}
                   onChange={() => this.classChanged()}
                   defaultValue='' required>
                   <option hidden disabled value=''> -- Select class -- </option>
                   {this.props.classes.map((el, index) =>
                      <option key={index} value={el.uri}>{el.label}</option>)}
              </Form.Control>
            </Form.Group>}
            {this.props.vocabularies && this.class && this.class.value && this.class.value === 'http://sw.islab.ntua.gr/semaspace/model/AssertionCollection' &&
            <Form.Group>
              <Form.Label>Thesaurus/Ontology</Form.Label>
              <Form.Control as="select" ref={node => (this.ontology = node)}
//                   defaultValue='' required>
                   defaultValue=''>
                   <option hidden disabled value=''> -- Select thesaurus/ontology -- </option>
                   {this.props.vocabularies.map((el, index) =>
                      <option key={index} value={el['@id']}>{filterByLanguage(el  ,'http://www.w3.org/2000/01/rdf-schema#label', 'el')}</option>)}
              </Form.Control>
            </Form.Group>}

{/*            <Form.Group>
              <Form.Label>Type</Form.Label>
              <Form.Control as="select" ref={node => (this.type = node)}
                      onChange={() => this.setState({ error: false })}
                      isInvalid={false}>
                  <option hidden disabled value=''> -- Select a type -- </option>
                  <option value="data-collection">Data Collection Mapping</option>
              </Form.Control>
            </Form.Group> */}
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" variant="primary">
              OK
            </Button>
            <Button variant="secondary" onClick={this.props.onClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
  )}
}

export default NewMappingModal;
