import { API_BASE_URL } from '../../constants/index.js';

import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import { createDataset, updateDataset, deleteDataset, getDatasets, getDataset, publishDataset, unpublishDataset, republishDatasetMetadata, publishUnpublishedContent, flipDatasetVisibility } from '../../utils/APIUtils';
import { indexDataset, unindexDataset } from '../../utils/APIUtils';
import { igetDatasets, igetUserDatasets } from '../../utils/DatasetUtils.js';

import NewMappingModal from "./modals/NewMappingModal.js";
import Datasets from "../Datasets.js";
import DatasetEditor from "./DatasetEditor.js";
//import CatalogEditor from "./api/CatalogEditor.js";
import PublishedSchema from "./PublishedSchema.js";

import NewAlignmentModal from "./modals/NewAlignmentModal.js";
import DeleteModal from "./modals/DeleteModal.js";

import { getOntologyQueryProperties, getDataAnnotators, getPreprocessFunctions } from '../../utils/APIUtils.js'
import { Localizer } from '../../config/localizer.js'

export class UserSpace extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collectionCatalogModalShow: false,
      collectionCatalogModalDataset: null,
      collectionCatalogsOpen: true,
      collectionCatalogs: [],
      collectionCurrentCatalog: null,

      collectionDatasetModalShow: false,
      collectionDatasetModalDataset: null,
      collectionDatasetsOpen: true,
      collectionDatasets: [],
      collectionCurrentDataset: null,

      vocabularyCatalogModalShow: false,
      vocabularyCatalogModalDataset: null,
      vocabularyCatalogsOpen: true,
      vocabularyCatalogs: [],
      vocabularyCurrentCatalog: null,

      vocabularyDatasetModalShow: false,
      vocabularyDatasetModalDataset: null,
      vocabularyDatasetsOpen: true,
      vocabularyDatasets: [],
      vocabularyCurrentDataset: null,

      annotationDatasetModalShow: false,
      annotationDatasetModalDataset: null,
      annotationDatasetsOpen: true,
      annotationDatasets: [],
      annotationCurrentDataset: null,

      alignmentDatasetModalShow: false,
      alignmentDatasetModalDataset: null,
      alignmentDatasetsOpen: true,
      alignmentDatasets: [],
      alignmentCurrentDataset: null,

      publishedDatasets: [],
      selectedPublishedDataset: null,

      publishedVocabularies: [],

      queryOntologyProperties: [],
      dataAnnotators: []
    };


     this.vocabularyTypes = [ { label: 'Temporal', uri: 'http://sw.islab.ntua.gr/semaspace/model/TemporalCollection' },
                              { label: 'Spatial', uri: 'http://sw.islab.ntua.gr/semaspace/model/SpatialCollection' },
                              { label: 'Thesaurus/Ontology', uri: 'http://sw.islab.ntua.gr/semaspace/model/ThesaurusCollection' },
                              { label: 'Assertion Box', uri: 'http://sw.islab.ntua.gr/semaspace/model/AssertionCollection' },]
  }

  isAdminUser() {
    return this.state.user.type === "ADMIN";
  }

  sortbyname(a, b) {
    if (a.name < b.name) {
      return -1;
    } else if (a.name > b.name) {
      return 1;
    } else {
      return 0;
    }
  }

  sortannotators(a, b) {
    if (a.title < b.title) {
      return -1;
    } else if (a.title > b.title) {
      return 1;
    } else {
      return 1;
    }
  }

  sortfunctions(a, b) {
    if (a.uri < b.uri) {
      return -1;
    } else if (a.uri > b.uri) {
      return 1;
    } else {
      return 1;
    }
  }

  componentDidMount() {
    getDatasets('collection-dataset')
      .then(collectionDatasets => {
        this.setState({ collectionDatasets });
      })

    getDatasets('collection-catalog')
      .then(collectionCatalogs => {
        this.setState({ collectionCatalogs });
      })

    getDatasets('vocabulary-dataset')
      .then(vocabularyDatasets => {
        this.setState({ vocabularyDatasets });
      })

    getDatasets('annotation-dataset')
      .then(annotationDatasets => {
        this.setState({ annotationDatasets });
      })

    getDatasets('vocabulary-catalog')
      .then(vocabularyCatalogs => {
        this.setState({ vocabularyCatalogs });
      })

    getDatasets('alignment-dataset')
      .then(alignmentDatasets => {
        this.setState({ alignmentDatasets });
      })

    igetUserDatasets('http://sw.islab.ntua.gr/semaspace/model/DataCollection')
      .then(json => {
          const publishedDatasets  = json.map(el => {return { dataset: el, selected: false } } )
          this.setState({ publishedDatasets})
        })

    igetDatasets('http://sw.islab.ntua.gr/semaspace/model/VocabularyCollection')
      .then(json => {
        this.setState({ publishedVocabularies: json})
          // const publishedVocabularies  = json.map(el => {return { dataset: el, selected: false } } )
        })

    getOntologyQueryProperties()
      .then(json => this.setState({ queryOntologyProperties: json}))

    getDataAnnotators()
        .then(json => this.setState({ dataAnnotators: json.sort(this.sortannotators)}))

    getPreprocessFunctions()
            .then(json => this.setState({ preprocessFunctions: json.sort(this.sortfunctions)}))

    const eventSource = new EventSource(API_BASE_URL + '/server/sse');

    eventSource.addEventListener('mapping', (event) => {
      // console.log(event.data)
      if (this.datasetEditor) {
        this.datasetEditor.handleServerEvent(JSON.parse(event.data))
      }
      if (this.vocabularyDatasetEditor) {
        this.vocabularyDatasetEditor.handleServerEvent(JSON.parse(event.data))
      }
      if (this.alignmentDatasetEditor) {
        this.alignmentDatasetEditor.handleServerEvent(JSON.parse(event.data))
      }
    });

    eventSource.addEventListener('annotator', (event) => {
      // console.log(event.data)
      if (this.publishedSchema) {
        this.publishedSchema.handleServerEvent('annotator', JSON.parse(event.data))}
      }
    );
    eventSource.addEventListener('index', (event) => {
        // console.log(event.data)
        if (this.publishedSchema) {
          this.publishedSchema.handleServerEvent('index', JSON.parse(event.data))}
        }
    );
    eventSource.addEventListener('edits', (event) => {
      if (this.publishedSchema) {
        this.publishedSchema.handleServerEvent('edits', JSON.parse(event.data))}
    }
    );
    eventSource.addEventListener('dataset', (event) => {
      // console.log(event.data)
      this.handleServerEvent(JSON.parse(event.data))}
    );
}

handleServerEvent(message) {
  // console.log(">>>>>>>>>>>>>>>>>>>>>>")
  // console.log(message)

  if (this.state.collectionCurrentDataset && this.state.collectionCurrentDataset.id === message.id) {
    this.setState({ collectionCurrentDataset: {...this.state.collectionCurrentDataset,  [message.type + 'State']: message.state, [message.type + 'StartedAt']: message.startedAt, [message.type + 'CompletedAt']: message.completedAt }})
  }

  if (this.state.vocabularyCurrentDataset && this.state.vocabularyCurrentDataset.id === message.id) {
    this.setState({ vocabularyCurrentDataset: {...this.state.vocabularyCurrentDataset,  [message.type + 'State']: message.state, [message.type + 'StartedAt']: message.startedAt, [message.type + 'CompletedAt']: message.completedAt }})
  }

  if (this.state.alignmentCurrentDataset && this.state.alignmentCurrentDataset.id === message.id) {
    this.setState({ alignmentCurrentDataset: {...this.state.alignmentCurrentDataset,  [message.type + 'State']: message.state, [message.type + 'StartedAt']: message.startedAt, [message.type + 'CompletedAt']: message.completedAt }})
  }

}

executeAction(action, params) {
  // console.log(action);
  // console.log(params);
  if (action === 'create-dataset') {
    var links = [];
    if (params.ontology) {
      links.push({ type: 'ABOX_FOR', value: params.ontology });
    }
    if (params.source) {
      links.push({ type: 'SOURCE', value: params.source });
    }
    if (params.target) {
      links.push({ type: 'TARGET', value: params.target });
    }
    if (params.id && params.id != null) {
      updateDataset(params.id, params.name, params.scope + '-' + params.type, params.uri, params.asProperty, links)
        .then(response => {
          if (params.type === 'dataset') {
            var datasets = this.state[params.scope + 'Datasets'].map(el => el.id === params.id ? response : el);
            this.setState( {[params.scope + 'Datasets']: datasets, [params.scope + 'CurrentDataset']: response } )

          } else if (params.type === 'catalog') {
            var catalogs = this.state[params.scope + 'Catalogs'].map(el => el.id === params.id ? response : el);
            this.setState( {[params.scope + 'Catalogs']: catalogs, [params.scope + 'CurrentCatalog']: response } )
          }
        });
    } else {
      createDataset(params.name, params.scope + '-' + params.type, params.uri, params.asProperty, links)
        .then(response => {
          if (params.type === 'dataset') {
            this.setState( {[params.scope + 'Datasets']: this.state[params.scope + 'Datasets'].slice().concat(response)} )
          } else if (params.type === 'catalog') {
            this.setState( {[params.scope + 'Catalogs']: this.state[params.scope + 'Catalogs'].slice().concat({...response, datasets:[]} )} )
          }
        });
      }
  } else if (action === 'select-dataset') {
      getDataset(params.id)
        .then(json => {
          if (params.type === 'catalog') {
            this.setState({ [params.scope + 'CurrentCatalog']: json, [params.scope + 'CurrentDataset']: null })
          } else if (params.type === 'dataset') {
            this.setState({ [params.scope + 'CurrentDataset']: json, [params.scope + 'CurrentCatalog']: null })
          }
        })
    } else  if (action === 'delete-dataset') {
      this.setState({ deleteModalShow:true, deleteModalCommand: 'delete-dataset', deleteModalParams:params})
    } else  if (action === 'delete-dataset-ok') {
      deleteDataset(params.id)
        .then(json => {
          if (json.success) {
            if (params.type === 'catalog') {
              var catalogs = this.state[params.scope + 'Catalogs'].filter(el => el.id !== params.id)
              this.setState( { [params.scope + 'Catalogs']: catalogs, [params.scope + 'CurrentDataset']: null, [params.scope + 'CurrentCatalog']: null} )
            } else if (params.type === 'dataset') {
              var datasets = this.state[params.scope + 'Datasets'].filter(el => el.id !== params.id)
              this.setState( {[params.scope + 'Datasets']: datasets, [params.scope + 'CurrentDataset']: null, [params.scope + 'CurrentCatalog']: null} )
            }
          }})
    } else if (action === 'add-dataset-to-catalog') {
      var datasets = this.state[params.scope + 'CurrentCatalog'].datasets.slice().concat(this.state[params.scope + 'Datasets'].filter(el => el.id === params.id))
      this.setState({ [params.scope + 'CurrentCatalog']: {...this.state[params.scope + 'CurrentCatalog'], datasets: datasets }});
    } else if (action === 'remove-dataset-from-catalog') {
      var datasets = this.state[params.scope + 'CurrentCatalog'].datasets.filter(el => el.id !== params.id)
      this.setState({ [params.scope + 'CurrentCatalog']: {...this.state[params.scope + 'CurrentCatalog'], datasets: datasets }});

    } else if (action === 'publish-dataset') {
        publishDataset(params.id, params.visibility)
          .then(() => this.updateState(params, 'PUBLISHING'))

    } else if (action === 'publish-unpublished-content') {
        publishUnpublishedContent(params.id)
          .then(() => this.updateState(params, 'PUBLISHING'))

    } else if (action === 'republish-dataset-metadata') {
        republishDatasetMetadata(params.id)
          .then(() => this.updateState(params, 'PUBLISHING'))

    } else if (action === 'unpublish-dataset') {
        unpublishDataset(params.id)
          .then(() => this.updateState(params, 'UNPUBLISHING'))

    } else if (action === 'flip-dataset-visibility') {
        flipDatasetVisibility(params.id)
          // .then(() => this.updateState(params, 'UNPUBLISHING'))

    } else if (action === 'index-dataset') {
        indexDataset(params.id)
          .then(() => this.updateState(params, 'INDEXING'))

    } else if (action === 'unindex-dataset') {
        unindexDataset(params.id)
          .then(() => this.updateState(params, 'UNINDEXING'))
    } else if (action === 'update-dataset') {
      if (params.type === 'dataset') {
        this.setState({ [params.scope + 'DatasetModalShow']: true, [params.scope + 'DatasetModalDataset']: this.state[params.scope + 'CurrentDataset'] ? this.state[params.scope + 'CurrentDataset'] : this.state[params.scope + 'CurrentCatalog']})
      } else {
        this.setState({ [params.scope + 'CatalogModalShow']: true, [params.scope + 'CatalogModalDataset']: this.state[params.scope + 'CurrentCatalog'] ? this.state[params.scope + 'CurrentCatalog'] : this.state[params.scope + 'CurrentCatalog']})
      }
    }
}

updateState(params, state) {
  if (params.type === 'catalog') {
    this.setState({ [params.scope + 'CurrentCatalog']: {...this.state[params.scope + 'CurrentCatalog'], publishState:state, publishStartedAt: new Date() }})
  } else if (params.type === 'dataset') {
    this.setState({ [params.scope + 'CurrentDataset']: {...this.state[params.scope + 'CurrentDataset'], publishState:state, publishStartedAt: new Date() }})
  }
}


  publishedDatasetActions(action, params) {
    if (action === 'select-dataset') {
       this.setState({ selectedPublishedDataset: null}, () => this.setState({ selectedPublishedDataset: params.dataset}))
    }
  }

  isTemporal(dataset) {
    for (var i in dataset.typeUri) {
      if (dataset.typeUri[i]=== 'http://sw.islab.ntua.gr/semaspace/model/TemporalCollection') {
        return true;
      }
    }

    return false;
  }

  isSpatial(dataset) {
    for (var i in dataset.typeUri) {
      if (dataset.typeUri[i]=== 'http://sw.islab.ntua.gr/semaspace/model/SpatialCollection') {
        return true;
      }
    }

    return false;
  }

  isThesaurus(dataset) {
    for (var i in dataset.typeUri) {
      if (dataset.typeUri[i]=== 'http://sw.islab.ntua.gr/semaspace/model/ThesaurusCollection') {
        return true;
      }
    }

    return false;
  }

  render() {

    // console.log(this.state.vocabularyDatasets);

    return (

      <Container>
        <Row>
          <Col>
            <Tabs defaultActiveKey="datasets">
{/*              <Tab eventKey="profile" title="Profile">
                Username: {this.props.user.username}
              </Tab> */}

              <Tab eventKey="datasets" title={Localizer.data_import[this.props.language]}>
                <Container>
                  <Row>
                    <Col md={3}>
                      <Container  className="groupborder">
                        <Row className="header">
                          <Col>
                            {Localizer.catalogs[this.props.language]}
                          </Col>
                          <Col className="mybutton" md="auto">
                            <Button type="button" className="menubutton"  aria-label="Add"  onClick={() => this.setState({ collectionCatalogModalShow: true, collectionCatalogModalDataset: null})}><span className='fa fa-plus'></span></Button>
                            {this.state.collectionCatalogModalShow &&
                              <NewMappingModal show={this.state.collectionCatalogModalShow}
                                               type='Catalog'
                                               dataset={this.state.collectionCatalogModalDataset}
                                               onOK={(id, name) => {this.executeAction('create-dataset', {id: id, name: name, scope: 'collection', type:'catalog', uri: 'http://sw.islab.ntua.gr/semaspace/model/DataCatalog'}); this.setState({ collectionCatalogModalShow: false})}}
                                               onClose={() => this.setState({ collectionCatalogModalShow: false})}/>}
                          </Col>
                          <Col className="mybutton" md="auto">
                            <Button type="button" className="menubutton"  aria-label="Toggle" onClick={() => this.setState({ collectionCatalogsOpen: !this.state.collectionCatalogsOpen})}><span className={this.state.collectionCatalogsOpen ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'}></span></Button>
                          </Col>
                        </Row>
                        {this.state.collectionCatalogs.map((el,index) => (
                        <Row key={index}>
                          <Col>
                            <span className="lbutton alink" onClick={() => this.executeAction('select-dataset', {id: el.id, scope: 'collection', type: 'catalog'})}>
                            {el.name}
                            </span>
                          </Col>
                        </Row>))}
                      </Container>

                      <Container  className="groupborder">
                        <Row className="header">
                          <Col>
                            {Localizer.datasets[this.props.language]}
                          </Col>
                          <Col className="mybutton" md="auto">
                            <Button type="button" className="menubutton"  aria-label="Add"  onClick={() => this.setState({ collectionDatasetModalShow: true, collectionDatasetModalDataset: null})}><span className='fa fa-plus'></span></Button>
                            {this.state.collectionDatasetModalShow &&
                              <NewMappingModal show={this.state.collectionDatasetModalShow}
                                               type='Dataset'
                                               dataset={this.state.collectionDatasetModalDataset}
                                               onOK={(id, name) => {this.executeAction('create-dataset', {id: id, name: name, scope: 'collection', type:'dataset', uri: 'http://sw.islab.ntua.gr/semaspace/model/DataCollection'}); this.setState({ collectionDatasetModalShow: false})}}
                                               onClose={() => this.setState({ collectionDatasetModalShow: false})}/>}
                          </Col>
                          <Col className="mybutton" md="auto">
                            <Button type="button" className="menubutton"  aria-label="Toggle" onClick={() => this.setState({ collectionDatasetsOpen: !this.state.collectionDatasetsOpen})}><span className={this.state.collectionDatasetsOpen ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'}></span></Button>
                          </Col>
                        </Row>
                        {this.state.collectionDatasets.sort(this.sortbyname).map((el,index) => (
                        <Row key={index}>
                          <Col>
                            <span className="lbutton alink" onClick={() => this.executeAction('select-dataset', {id: el.id, scope: 'collection', type: 'dataset'})}>
                            {el.name}
                            </span>
                          </Col>
                        </Row>))}
                      </Container>
                    </Col>
                    <Col>
                       {(this.state.collectionCurrentDataset || this.state.collectionCurrentCatalog) &&
                       <DatasetEditor ref={node => (this.datasetEditor = node)}
                                      user={this.props.user}
                                      visibility={true}
                                      datasets={this.state.collectionDatasets}
                                      type = {this.state.collectionCurrentDataset ? 'dataset' : 'catalog'}
                                      scope = 'collection'
                                      dataset={this.state.collectionCurrentDataset ? this.state.collectionCurrentDataset : this.state.collectionCurrentCatalog}
                                      metadata={true}
                                      actions={(action, params) => this.executeAction(action, { ...params, scope:'collection'})}/>}
                    </Col>
                  </Row>
                </Container>
              </Tab>

              <Tab eventKey="vocabularies" title={Localizer.vocabulary_import[this.props.language]}>
                <Container>
                  <Row>
                    <Col md={3}>
                      <Container  className="groupborder">
                        <Row className="header">
                          <Col>
                            {Localizer.catalogs[this.props.language]}
                          </Col>
                          <Col className="mybutton" md="auto">
                            <Button type="button" className="menubutton"  aria-label="Add"  onClick={() => this.setState({ vocabularyCatalogModalShow: true, vocabularyCatalogModalDataset: null})}><span className='fa fa-plus'></span></Button>
                            {this.state.vocabularyCatalogModalShow &&
                              <NewMappingModal show={this.state.vocabularyCatalogModalShow}
                                               type='Vocabulary Group'
                                               dataset={this.state.vcabularyCatalogModalDataset}
                                               onOK={(id, name) => {this.executeAction('create-dataset', {id: id, name: name, scope: 'vocabulary', uri: 'http://sw.islab.ntua.gr/semaspace/model/DataCatalog', type:'catalog'}); this.setState({ vocabularyCatalogModalShow: false})}}
                                               onClose={() => this.setState({ vocabularyCatalogModalShow: false})}/>}
                          </Col>
                          <Col className="mybutton" md="auto">
                            <Button type="button" className="menubutton"  aria-label="Toggle" onClick={() => this.setState({ vocabularyCatalogsOpen: !this.state.vocabularyCatalogsOpen})}><span className={this.state.vocabularyCatalogsOpen ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'}></span></Button>
                          </Col>
                        </Row>
                        {this.state.vocabularyCatalogs.map((el,index) => (
                        <Row key={index}>
                          <Col>
                            <span className="lbutton alink" onClick={() => this.executeAction('select-dataset', {id: el.id, scope: 'vocabulary', type: 'catalog'})}>
                            {el.name}
                            </span>
                          </Col>
                        </Row>))}
                      </Container>


                      <Container  className="groupborder">
                        <Row className="header">
                          <Col>
                            {Localizer.vocabularies[this.props.language]}
                          </Col>
                          <Col className="mybutton" md="auto">
                            <Button type="button" className="menubutton"  aria-label="Add"  onClick={() => this.setState({ vocabularyDatasetModalShow: true, vocabularyDatasetModalDataset: null})}><span className='fa fa-plus'></span></Button>
                            {this.state.vocabularyDatasetModalShow &&
                              <NewMappingModal show={this.state.vocabularyDatasetModalShow}
                                              type='Vocabulary'
                                              dataset={this.state.vocabularyDatasetModalDataset}
                                              types={this.vocabularyTypes}
                                              vocabularies={this.state.publishedVocabularies}
                                              onOK={(id, name, uri, property, ontology) => {this.executeAction('create-dataset', {id: id, name: name, scope: 'vocabulary', type:'dataset', uri: uri, ontology:ontology}); this.setState({ vocabularyDatasetModalShow: false})}}
                                              onClose={() => this.setState({ vocabularyDatasetModalShow: false})}/>}
                          </Col>
                          <Col className="mybutton" md="auto">
                            <Button type="button" className="menubutton"  aria-label="Toggle" onClick={() => this.setState({ vocabularyDatasetsOpen: !this.state.vocabularyDatasetsOpen})}><span className={this.state.vocabularyDatasetsOpen ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'}></span></Button>
                          </Col>
                        </Row>
                        {this.state.vocabularyDatasets.sort(this.sortbyname).map((el,index) => (
                        <Row key={index}>
                          <Col md="auto">
                            {this.isTemporal(el) && <i className="fa fa-calendar"></i>}
                            {this.isSpatial(el) && <i className="fa fa-globe"></i>}
                            {this.isThesaurus(el) && <i className="fa fa-list"></i>}
                          </Col>
                          <Col>
                            <span className="lbutton alink" onClick={() => this.executeAction('select-dataset', {id: el.id, scope: 'vocabulary',type: 'dataset'})}>
                            {el.name}
                            </span>
                          </Col>
                        </Row>))}
                      </Container>


                    </Col>
                    <Col>
                       {(this.state.vocabularyCurrentCatalog || this.state.vocabularyCurrentDataset) &&
                       <DatasetEditor ref={node => (this.vocabularyDatasetEditor = node)}
                                      user={this.props.user}
                                      visibility={true}
                                      datasets={this.state.vocabularyDatasets}
                                      type = {this.state.vocabularyCurrentDataset ? 'dataset' : 'catalog'}
                                      scope = 'vocabulary'
                                      dataset={this.state.vocabularyCurrentDataset ? this.state.vocabularyCurrentDataset : this.state.vocabularyCurrentCatalog}
                                      metadata={true}
                                      actions={(action, params) => this.executeAction(action, { ...params, scope:'vocabulary'})}/>}
                    </Col>
                  </Row>
                </Container>
              </Tab>

              <Tab eventKey="alignments" title={Localizer.alignments[this.props.language]}>
                <Container>
                  <Row>
                    <Col md={3}>
{/*                      <Container  className="groupborder">
                        <Row className="header">
                          <Col>
                            Groups
                          </Col>
                          <Col className="mybutton" md="auto">
                            <Button type="button" className="menubutton"  aria-label="Add"  onClick={() => this.setState({ vocabularyCatalogModalShow: true})}><span className='fa fa-plus'></span></Button>
                            <NewMappingModal type='Vocabulary Group' show={this.state.vocabularyCatalogModalShow}
                                         onOK={(name) => {this.executeAction('create-dataset', {name: name, scope: 'vocabulary', type:'catalog'}); this.setState({ vocabularyCatalogModalShow: false})}}
                                         onClose={() => this.setState({ vocabularyCatalogModalShow: false})}/>
                          </Col>
                          <Col className="mybutton" md="auto">
                            <Button type="button" className="menubutton"  aria-label="Toggle" onClick={() => this.setState({ vocabularyCatalogsOpen: !this.state.vocabularyCatalogsOpen})}><span className={this.state.vocabularyCatalogsOpen ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'}></span></Button>
                          </Col>
                        </Row>
                        {this.state.vocabularyCatalogs.map((el,index) => (
                        <Row key={index}>
                          <Col>
                            <span className="abutton" onClick={() => this.executeAction('select-dataset', {id: el.id, scope: 'vocabulary', type: 'catalog'})}>
                            {el.name}
                            </span>
                          </Col>
                        </Row>))}
                      </Container> */}


                      <Container  className="groupborder">
                        <Row className="header">
                          <Col>
                            {Localizer.alignments[this.props.language]}
                          </Col>
                          <Col className="mybutton" md="auto">
                            <Button type="button" className="menubutton"  aria-label="Add"  onClick={() => this.setState({ alignmentDatasetModalShow: true, alignmentDatasetModalDataset: null})}><span className='fa fa-plus'></span></Button>
                            {this.state.alignmentDatasetModalShow &&
                              <NewAlignmentModal show={this.state.alignmentDatasetModalShow}
                                                 type='Alignment'
                                                 dataset={this.state.alignmentDatasetModalDataset}
                                                 types={this.vocabularyTypes}
                                                 vocabularies={this.state.publishedVocabularies}
                                                 onOK={(id, name, uri, source, target) => {this.executeAction('create-dataset', {id: id, name: name, scope:'alignment', type:'dataset', uri: uri, source:source, target:target}); this.setState({ alignmentDatasetModalShow: false})}}
                                                 onClose={() => this.setState({ alignmentDatasetModalShow: false})}/>}
                          </Col>
                          <Col className="mybutton" md="auto">
                            <Button type="button" className="menubutton"  aria-label="Toggle" onClick={() => this.setState({ alignmentDatasetsOpen: !this.state.alignmentDatasetsOpen})}><span className={this.state.alignmentDatasetsOpen ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'}></span></Button>
                          </Col>
                        </Row>
                        {this.state.alignmentDatasets.sort(this.sortbyname).map((el,index) => (
                        <Row key={index}>
                          <Col>
                            <span className="lbutton alink" onClick={() => this.executeAction('select-dataset', {id: el.id, scope: 'alignment', type: 'dataset'})}>
                            {el.name}
                            </span>
                          </Col>
                        </Row>))}
                      </Container>
                    </Col>
                    <Col>
{/*                      {this.state.alignmentCurrentCatalog &&
                      <CatalogEditor user={this.props.user}
                                     datasets={this.state.alignmentDatasets}
                                     catalog={this.state.alignmentCurrentCatalog}
                                     actions={(action, params) => this.executeAction(action, { ...params, scope:'alignment'})}/>} */}
                      {this.state.alignmentCurrentDataset &&
                      <DatasetEditor ref={node => (this.alignmentDatasetEditor = node)}
                                     user={this.props.user}
                                     visibility={false}
                                     type = 'dataset'
                                     scope = 'alignment'
                                     dataset={this.state.alignmentCurrentDataset}
                                     actions={(action, params) => this.executeAction(action, { ...params, scope:'alignment'})}/>}
                    </Col>
                  </Row>
                </Container>
              </Tab>

{/*              <Tab eventKey="annotations" title={Localizer.annotations_import[this.props.language]}>
                <Container>
                  <Row>
                    <Col md={3}>
                      <Container  className="groupborder">
                        <Row className="header">
                          <Col>
                            {Localizer.annotations[this.props.language]}
                          </Col>
                          <Col className="mybutton" md="auto">
                            <Button type="button" className="menubutton"  aria-label="Add"  onClick={() => this.setState({ annotationDatasetModalShow: true})}><span className='fa fa-plus'></span></Button>
                            <NewMappingModal type='Annotation' show={this.state.annotationDatasetModalShow}
                                         queryProperties={this.state.queryOntologyProperties}
                                         onOK={(name, type, property) => {this.executeAction('create-dataset', {name: name, scope: 'annotation', type:'dataset', uri: 'http://sw.islab.ntua.gr/semaspace/model/AnnotationSet', asProperty: property}); this.setState({ newAnnotationDatasetModalShow: false})}}
                                         onClose={() => this.setState({ annotationDatasetModalShow: false})}/>
                          </Col>
                          <Col className="mybutton" md="auto">
                            <Button type="button" className="menubutton"  aria-label="Toggle" onClick={() => this.setState({ annotationDatasetsOpen: !this.state.annotationDatasetsOpen})}><span className={this.state.annotationDatasetsOpen ? 'fa fa-angle-double-up' : 'fa fa-angle-double-down'}></span></Button>
                          </Col>
                        </Row>
                        {this.state.annotationDatasets.sort(this.sortbyname).map((el,index) => (
                        <Row key={index}>
                          <Col>
                            <span className="lbutton alink" onClick={() => this.executeAction('select-dataset', {id: el.id, scope: 'annotation',type: 'dataset'})}>
                            {el.name}
                            </span>
                          </Col>
                        </Row>))}
                      </Container>
                    </Col>
                    <Col>
                      {this.state.annotationCurrentDataset &&
                      <DatasetEditor ref={node => (this.annotationDatasetEditor = node)}
                                     user={this.props.user}
                                     visibility={false}
                                     type = 'dataset'
                                     dataset={this.state.annotationCurrentDataset}
                                     actions={(action, params) => this.executeAction(action, { ...params, scope:'annotation'})}/>}
                    </Col>
                  </Row>
                </Container>
              </Tab> */}


              <Tab eventKey="schema" title={Localizer.published_datasets[this.props.language]}>
                <Container>
                  <Row>
                    <Col md={3}>
                      <Container  className="groupborder">
                        <Row className="header">
                          <Col>
                            {Localizer.published_datasets[this.props.language]}
                          </Col>
                        </Row>
                        <Row>
                        <Datasets user={this.props.currentUser}
                                  datasets={this.state.publishedDatasets}
                                  actions={(action, params) => this.publishedDatasetActions(action, params)}/></Row>
                      </Container>
                    </Col>
                    <Col>
                      <PublishedSchema ref={node => (this.publishedSchema = node)}
                                       language={this.props.language}
                                       schemaDataset={this.state.selectedPublishedDataset}
                                       queryProperties={this.state.queryOntologyProperties}
                                       dataAnnotators={this.state.dataAnnotators}
                                       preprocessFunctions={this.state.preprocessFunctions}
                                       vocabularies={this.state.publishedVocabularies}/>
                    </Col>
                  </Row>
                </Container>
              </Tab>
            </Tabs>
          </Col>
        </Row>

        {this.state.deleteModalShow && <DeleteModal show={this.state.deleteModalShow}
                                command={this.state.deleteModalCommand}
                                params={this.state.deleteModalParams}
                                actions={(choice, command, params) => { this.setState( {deleteModalShow:false }); return (choice === 'ok' ? this.executeAction(command + '-ok', params): null)} }
                                />}
      </Container>
    );
  }
}



export default UserSpace;
