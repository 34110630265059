import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ClipLoader from "react-spinners/ClipLoader";

import { TIME_OPTIONS } from "../../constants/index.js"

export class ExecutableState extends Component {

  renderMapName(name) {
    return name.substring(Math.max(name.lastIndexOf("/"),name.lastIndexOf("#")) + 1);
  }
  render() {

    var time = null;
    var cz
    var ct
    if (this.props.value.executeState === 'NOT_EXECUTED') {
      cz = 'not-executed'
      ct = 'Not executed'
    } else if (this.props.value.executeState === 'EXECUTING') {
      cz = 'executing'
      ct = 'Executing'
      time = '(since ' + new Date(this.props.value.executeStartedAt).toLocaleDateString('en-UK', TIME_OPTIONS) + ')';
    } else if (this.props.value.executeState === 'EXECUTED') {
      cz = 'executed'
      ct = 'Executed'
      time = '(at ' + new Date(this.props.value.executeCompletedAt).toLocaleDateString('en-UK', TIME_OPTIONS) + ')'
    } else if (this.props.value.executeState === 'EXECUTION_FAILED') {
      cz = 'failed'
      ct = 'Execution failed'
    } else {
      cz = 'not-executed'
      ct = 'Not executed'
    }
// console.log(this.props.value);

    return (
<div>
      <div className={cz}>
      {ct}
      </div>
      <div>
        {time &&
        <Row className="executeinfo">
          <Col md="auto">
            <span className='times'>{time}</span>
          </Col>
          <Col>
          <span className='times'>{this.props.value.count >=0 ? this.props.value.count: ""}</span>
          </Col>
        </Row>}
        {this.props.value.maps &&
        <Container className='border'>
      {this.props.value.maps.map((m,index) =>
          <Row key={index} className={m.completed ? 'executed' : 'executing'}>
            <Col className='executinginfo'> {this.renderMapName(m.triplesMap)}</Col>
            <Col className='executinginfo'> {this.renderMapName(m.dataSource)}</Col>
            <Col className='executinginfo'>{m.key}</Col>
            <Col md='auto' className='executinginfo'>{m.partialCount? m.partialCount: ''}</Col>
            <Col md='auto' className='executinginfo executed'>{m.totalCount? m.totalCount-m.failures: ''}</Col>
            <Col md='auto' className='executinginfo failed'>{m.totalCount? m.failures: ''}</Col>
            <Col>
            {m.started && !m.completed && !m.failed &&
              <ClipLoader
                css='spinner'
                size={12}
                color='orange'
                loading={true}
              />}
              {m.started && m.completed &&
                <span className="fa fa-check"></span>
              }
              {m.failed &&
                <span className="fa fa-times failed"></span>
              }
            </Col>
          </Row>
      )}
      </Container>}
      </div>
      </div>
    )
  }
}

export default ExecutableState;
