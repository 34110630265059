import React, { Component } from "react";

import { TIME_OPTIONS } from "../../constants/index.js"

export class ExecutableState extends Component {

  render() {
    var cz
    var ct
    if (this.props.value.indexState === 'NOT_INDEXED') {
      cz = 'not-executed'
      ct = 'Not indexed'
    } else if (this.props.value.indexState === 'INDEXING') {
      cz = 'executing'
      ct = 'Indexing (since ' + new Date(this.props.value.indexStartedAt).toLocaleDateString('en-UK', TIME_OPTIONS) + ')';
    } else if (this.props.value.indexState === 'INDEXED') {
      cz = 'executed'
      ct = 'Indexed (at ' + new Date(this.props.value.indexCompletedAt).toLocaleDateString('en-UK', TIME_OPTIONS) + ')'
    } else if (this.props.value.indexState === 'INDEXING_FAILED') {
      cz = 'failed'
      ct = 'Indexing failed'
    } else {
      cz = 'not-executed'
      ct = 'Not indexed'
    }

    return (
      <div className={cz}>
        {ct}
      </div>
    )
  }
}

export default ExecutableState;
