import { API_BASE_URL, ACCESS_TOKEN } from '../constants/index.js';

const sRequest = (options) => {
    const headers = new Headers()

    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
};

const sjRequest = (options) => {
  const headers = new Headers({
      'Content-Type': 'application/json',
  })

    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
};

const jsRequest = (options) => {
    const headers = new Headers()

    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
    .then(response =>
          response.ok ? response.json() : Promise.reject(response),
          () => Promise.reject()
    )
};

const jRequest = (options) => {
    return fetch(options.url, options)
    .then(response =>
          response.ok ? response.json() : Promise.reject(response),
          () => Promise.reject()
    )
};

export const request = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })

    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
    .then(response =>
          response.ok ? response.json() : Promise.reject(response),
          () => Promise.reject()
    )
};

export const jjrequest = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })

    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
    .then(response =>
          response.ok ? response :
          () => Promise.reject()
    )
};

const textrequest = (options) => {
    const headers = new Headers({
        'Content-Type': 'text/plain',
    })

    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
    // .then(response => {
    //   console.log("RESPONSE");
    //   console.log(response);
    //     response.text().then(text => {
    //         if(!response.ok) {
    //             return Promise.reject(text);
    //         }
    //         // console.log(text);
    //         return text;
    //     })}
    // );
};

const textRequest = (options) => {
    const headers = new Headers({
        'Content-Type': 'text/plain',
    })

    if(localStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
};

export function signin(loginRequest) {
    return request({
        url: API_BASE_URL + "/auth/signin",
        method: 'POST',
        body: JSON.stringify(loginRequest)
    });
}



export function getCurrentUser() {
    if(!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: API_BASE_URL + "/users/me",
        method: 'GET'
    });
}

export function getCurrentDatabase() {
    return request({
        url: API_BASE_URL + "/database/current",
        method: 'GET'
    });
}

export function getMappings(datasetId) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/mappings/getAll?datasetId=" + datasetId,
    method: 'GET'
  })
}

export function getMapping(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/mappings/get/" + id,
    method: 'GET'
  })
}

export function createMapping(datasetId, type, name, d2rml, parameters) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  var body = { name: name, d2rml: d2rml}

  if (parameters && parameters != null) {
    body = {...body, parameters: parameters}
  }

  return request({
    url:  API_BASE_URL + "/mappings/create?type=" + type + "&datasetId=" + datasetId,
    method: 'POST',
    body: JSON.stringify(body),
  })
}

export function executeMapping(id, instanceId) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textrequest({
    url:  API_BASE_URL + "/mappings/execute/" + id + (instanceId? "?instanceId=" + instanceId : ""),
    method: 'GET'
  })
}

export function clearMappingExecution(id, instanceId) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textrequest({
    url:  API_BASE_URL + "/mappings/clear-execution/" + id + (instanceId? "?instanceId=" + instanceId : ""),
    method: 'GET'
  })
}

export function updateMapping(id, name, d2rml, parameters) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  var body = {}
  if (name && name != null) {
    body = {...body, name: name}
  }
  if (d2rml && d2rml != null) {
    body = {...body, d2rml: d2rml}
  }
  if (parameters && parameters != null) {
    body = {...body, parameters: parameters}
  }
  return request({
    url:  API_BASE_URL + "/mappings/update/" + id,
    method: 'POST',
    body: JSON.stringify(body),
  })
}

export function createMappingParameterBinding(id, parameters) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/mappings/createParameterBinding/" + id,
    method: 'POST',
    body: JSON.stringify(parameters)
  })
}

export function deleteMappingParameterBinding(id, instanceId) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textRequest({
    url:  API_BASE_URL + "/mappings/deleteParameterBinding/" + id + "?instanceId=" + instanceId,
    method: 'DELETE'
  });
}

export function downloadMapping(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textrequest({
    url:  API_BASE_URL + "/mappings/download/" + id,
    method: 'GET'
  });

}

export function viewMappingExecution(id, instanceId) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textRequest({
    url:  API_BASE_URL + "/mappings/lastExecution/" + id + (instanceId? "?instanceId=" + instanceId : ""),
    method: 'GET'
  });

}


export function downloadMappingExecution(id, instanceId) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return sRequest({
    url:  API_BASE_URL + "/mappings/downloadLastExecution/" + id + (instanceId? "?instanceId=" + instanceId : ""),
    method: 'GET'
  });

}

export function deleteMapping(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textrequest({
    url:  API_BASE_URL + "/mappings/delete/" + id,
    method: 'DELETE'
  });

}

export function uploadMappingAuxiliaryFile(id, name, file) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  var formData = new FormData();
  formData.append('file', file);

  return sRequest({
    url:  API_BASE_URL + "/mappings/upload-file/" + id + "",
    method: 'POST',
    body: formData,
  })
}

export function createFile(name, datasetId, data) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  var formData = new FormData();
  formData.append('file', data);

  return jsRequest({
    url:  API_BASE_URL + "/files/create?name=" + encodeURIComponent(name) + "&datasetId=" + datasetId,
    method: 'POST',
    body: formData,
  })
}

export function updateFile(id, name, data) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  var body = new FormData();
  if (name && name != null) {
    body.append('name', name);
  }
  if (data && data != null) {
    body.append('file', data);
  }

  return jsRequest({
    url:  API_BASE_URL + "/files/update/" + id,
    method: 'POST',
    body: body,
  })
}

export function getFiles(datasetId) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/files/getAll?datasetId=" + datasetId,
    method: 'GET'
  })
}

export function deleteFile(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textrequest({
    url:  API_BASE_URL + "/files/delete/" + id,
    method: 'DELETE'
  });

}

export function createDataset(name, type, typeUri, asProperty, links) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/dataset/create?name=" + encodeURIComponent(name)
                       + "&type=" + type
                       + (typeUri ? "&typeUri=" + encodeURIComponent(typeUri) : "")
                       + (asProperty ? "&asProperty=" + encodeURIComponent(asProperty) : ""),
    method: 'POST',
    body: JSON.stringify(links)
  })
}

export function updateDataset(id, name, type, typeUri, asProperty, links) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/dataset/update/" + id + "?name=" + encodeURIComponent(name)
                       + "&type=" + type
                       + (typeUri ? "&typeUri=" + encodeURIComponent(typeUri) : "")
                       + (asProperty ? "&asProperty=" + encodeURIComponent(asProperty) : ""),
    method: 'POST',
    body: JSON.stringify(links)
  })
}


export function deleteDataset(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/dataset/delete/" + id,
    method: 'DELETE',
  })
}

export function getDataset(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/dataset/get/" + id,
    method: 'GET',
  })
}

export function getDatasets(type) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/dataset/getAll?type=" + type,
    method: 'GET',
  })
}

export function addDataset(id, toId) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/dataset/insert?id=" + id + "&toId=" + toId,
    method: 'POST',
  })
}

export function removeDataset(id, fromId) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/dataset/remove?id=" + id + "&fromId=" + fromId,
    method: 'POST',
  })
}

export function publishDataset(id, privat) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textrequest({
    url:  API_BASE_URL + "/dataset/publish/" + id + "?visibility=" + (privat?"private":"public"),
    method: 'POST',
  })
}

export function publishUnpublishedContent(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textrequest({
    url:  API_BASE_URL + "/dataset/publishUnpublishedContent/" + id,
    method: 'POST',
  })
}

export function republishDatasetMetadata(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textrequest({
    url:  API_BASE_URL + "/dataset/republishMetadata/" + id,
    method: 'POST',
  })
}

export function unpublishDataset(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textrequest({
    url:  API_BASE_URL + "/dataset/unpublish/" + id,
    method: 'POST',
  })
}

export function flipDatasetVisibility(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textrequest({
    url:  API_BASE_URL + "/dataset/flipVisibility/" + id,
    method: 'POST',
  })
}


export function indexDataset(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textrequest({
    url:  API_BASE_URL + "/dataset/index/" + id,
    method: 'POST',
  })
}

export function unindexDataset(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textrequest({
    url:  API_BASE_URL + "/dataset/unindex/" + id,
    method: 'POST',
  })
}

export function getDatasetSchema(uri) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/data/schema/get?dataset=" + encodeURIComponent(uri),
    method: 'GET',
  })
}

export function getDatasetPropertyValues(uri, path, mode) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/data/schema/getPropertyValues?uri=" + encodeURIComponent(uri) + "&mode=" + mode,
    body: JSON.stringify(path),
    method: 'POST',
  })
}

export function createAnnotationValidation(aegId) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/annotation-validation/create?aegId=" + aegId ,
    method: 'GET'
  })
}

export function downloadDatasetPropertyValues(uri, path, mode) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return sjRequest({
    url:  API_BASE_URL + "/data/schema/downloadPropertyValues?uri=" + encodeURIComponent(uri) + "&mode=" + mode,
    body: JSON.stringify(path),
    method: 'POST',
  })
}

export function getOntologyQueryProperties() {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/ontology/getQueryProperties",
    method: 'GET',
  })
}

export function getDataAnnotators() {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/annotator/services",
    method: 'GET',
  })
}

export function getPreprocessFunctions() {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/annotator/functions",
    method: 'GET',
  })
}

export function createAnnotator(datasetUri, onProperty, asProperty, annotator, thesaurus, parameters, preprocess, variant) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  var object = {datasetUri: datasetUri, onProperty: onProperty, asProperty: asProperty, annotator: annotator, variant:variant};

  Object.assign(object, thesaurus && { thesaurus: thesaurus },
                        parameters && { parameters: parameters },
                        (preprocess && preprocess.length > 0) && {preprocess: preprocess});


  return request({
    url:  API_BASE_URL + "/annotator/create",
    body: JSON.stringify(object),
    method: 'POST',
  })
}

export function deleteAnnotator(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textrequest({
    url:  API_BASE_URL + "/annotator/delete/" + id,
    method: 'DELETE'
  });

}

export function executeAnnotator(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textrequest({
    url:  API_BASE_URL + "/annotator/execute/" + id,
    method: 'POST',
  })
}

export function viewAnnotationExecution(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textRequest({
    url:  API_BASE_URL + "/annotator/lastExecution/" + id,
    method: 'GET'
  });

}

export function downloadAnnotationExecution(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return sRequest({
    url:  API_BASE_URL + "/annotator/downloadLastExecution/" + id,
    method: 'GET'
  });

}

export function viewFile(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textRequest({
    url:  API_BASE_URL + "/files/view/" + id,
    method: 'GET'
  });

}

export function getAnnotators(datasetUri) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/annotator/getAll?datasetUri=" + encodeURIComponent(datasetUri),
    method: 'GET',
  })
}

export function getAnnotatorsByUser(datasetUri) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/annotator/getAllByUser?datasetUri=" + encodeURIComponent(datasetUri),
    method: 'GET',
  })
}


export function resolveTime(text) {
  return jRequest({
    url:  "https://apps.islab.ntua.gr/intime/api/date?text=" + text,
    method: 'GET',
  })
}

export function resolveSpace(text) {
  return jRequest({
    url:  "https://apps.islab.ntua.gr/inplace/api/location?text=" + text,
    method: 'GET',
  })
}

export function resolveThesaurusTerm(text, index, vocabularies) {
  var vocs = [];
  if (vocabularies) {
    for (var i in vocabularies) {
      vocs.push(encodeURIComponent(vocabularies[i]))
    }
  }
  return jRequest({
    url:  "https://apps.islab.ntua.gr/inknowledge/api/lookup?text=" + text + "&index=" + index + "&limit=10" + (vocs.length ? "&graphs=" + vocs.join() : ""),
    method: 'GET',
  })
}

export function labelResource(r) {
  return request({
    url: API_BASE_URL + "/f/resources/label?resource=" + encodeURIComponent(r),
    method: 'GET',
  })
}

export function insideTime(resource, vocabularies) {
  vocabularies = vocabularies.map(el => encodeURIComponent(el));
  var voc = "";
  if (vocabularies.length > 0) {
    voc = vocabularies.join(",");
  }

  return jRequest({
    url: "https://apps.islab.ntua.gr/intime/api/inside?resource=" + encodeURIComponent(resource) + "&vocabulary=" + voc,
    method: 'GET'
  })
}

export function publishAnnotators(ids) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textrequest({
    url:  API_BASE_URL + "/annotator/publish?id=" + ids.join(','),
    method: 'POST',
  })
}

export function unpublishAnnotators(ids) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textrequest({
    url:  API_BASE_URL + "/annotator/unpublish?id=" + ids.join(','),
    method: 'POST',
  })
}

export function addDeleteAnnotationEdit(datasetUri, propertyUri, propertyValue, annotationValue) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/annotation/addDeleteEdit?datasetUri=" + encodeURIComponent(datasetUri)
                                        + "&propertyUri=" + encodeURIComponent(propertyUri)
                                        + "&propertyValue=" + encodeURIComponent(propertyValue)
                                        + "&annotationValue=" + encodeURIComponent(annotationValue),
    method: 'POST',
  })
}

export function viewValuesAnnotations(id, page, mode, annotators) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  //var annotatorUuids = annotators.join(",")

  return request({
    url:  API_BASE_URL + "/annotation-edit-group/view/" + id
                                                  + "?page=" + page
                                                  + "&mode=" + mode,
//						  + "&annotators=" + annotatorUuids,
    method: 'GET',
  })
}

export function viewAnnotationValidation(id, page, mode) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/annotation-validation/view/" + id
                                                  + "?page=" + page
                                                  + "&mode=" + mode,
    method: 'GET',
  })
}

export function downloadAnnotationValues(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return sjRequest({
    url:  API_BASE_URL + "/annotation-edit-group/downloadValues/" + id,
    method: 'GET',
  })
}


export function getAnnotationEditGroupsByUser(datasetUri) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/annotation-edit-group/getAllByUser?datasetUri=" + encodeURIComponent(datasetUri),
    method: 'GET',
  })
}

export function getAnnotationEditGroups(datasetUri) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/annotation-edit-group/getAll?datasetUri=" + encodeURIComponent(datasetUri),
    method: 'GET',
  })
}


export function executeAnnotationEdits(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return jjrequest({
    url:  API_BASE_URL + "/annotation-edit-group/execute/" + id,
    method: 'POST',
  })
}


export function publishAnnotationEdits(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return jjrequest({
    url:  API_BASE_URL + "/annotation-edit-group/publish/" + id,
    method: 'POST',
  })
}

export function unpublishAnnotationEdits(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return jjrequest({
    url:  API_BASE_URL + "/annotation-edit-group/unpublish/" + id,
    method: 'POST',
  })
}

export function viewAnnotationEditsExecution(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textRequest({
    url:  API_BASE_URL + "/annotation-edit-group/lastExecution/" + id,
    method: 'GET'
  });

}


export function commitAnnotationEdits(id, edits) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return jjrequest({
    url:  API_BASE_URL + "/annotation/commitAnnotationEdits/" + id,
    body: JSON.stringify(edits),
    method: 'POST',
  })
}

export function commitAnnotationValidationPage(id, edits) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return jjrequest({
    url:  API_BASE_URL + "/annotation-validation/commit-page/" + id,
    body: JSON.stringify(edits),
    method: 'POST',
  })
}



export function createVocabularizer(datasetUri, onProperty, name, separator) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }


  return request({
    url:  API_BASE_URL + "/vocabularizer/create",
    body: JSON.stringify({datasetUri, onProperty, name, separator}),
    method: 'POST',
  })
}

export function getVocabulizers(datasetUri) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/vocabularizer/getAll?datasetUri=" + encodeURIComponent(datasetUri),
    method: 'GET',
  })
}

export function deleteVocabularizer(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textrequest({
    url:  API_BASE_URL + "/vocabularizer/delete/" + id,
    method: 'DELETE'
  });

}

export function executeVocabularizer(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textrequest({
    url:  API_BASE_URL + "/vocabularizer/execute/" + id,
    method: 'POST'
  });

}

export function viewVocabularizerExecution(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return textRequest({
    url:  API_BASE_URL + "/vocabularizer/lastExecution/" + id,
    method: 'GET'
  });

}

export function publishVocabularizer(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return jjrequest({
    url:  API_BASE_URL + "/vocabularizer/publish/" + id,
    method: 'POST',
  })
}

export function unpublishVocabularizer(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return jjrequest({
    url:  API_BASE_URL + "/vocabularizer/unpublish/" + id,
    method: 'POST',
  })
}

export function indexVocabularizer(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return jjrequest({
    url:  API_BASE_URL + "/vocabularizer/index/" + id,
    method: 'POST',
  })
}

export function unindexVocabularizer(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return jjrequest({
    url:  API_BASE_URL + "/vocabularizer/unindex/" + id,
    method: 'POST',
  })
}

export function cleanupVocabularizer(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/vocabularizer/cleanup/" + id,
    method: 'GET',
  })
}

export function vocabularyVocabularizer(id) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/vocabularizer/vocabulary/" + id,
    method: 'GET',
  })
}

export function index(uri) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return jjrequest({
    url:  API_BASE_URL + "/index/index?uri=" + encodeURIComponent(uri),
    // body: JSON.stringify(path),
    body: JSON.stringify([]),
    method: 'POST',
  })
}

export function unindex(uri) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return jjrequest({
    url:  API_BASE_URL + "/index/unindex?uri=" + encodeURIComponent(uri),
    method: 'POST',
  })
}

export function getIndexes(datasetUri) {
  if(!localStorage.getItem(ACCESS_TOKEN)) {
      return Promise.reject("No access token set.");
  }

  return request({
    url:  API_BASE_URL + "/index/getAll?datasetUri=" + encodeURIComponent(datasetUri),
    method: 'GET',
  })
}
